import { useLocation } from 'react-router-dom';
import {
  SContainer,
  SPage,
  SProfilePictureContainer,
  SRecipientContainer,
} from './RecipientGroups.styles';
import { Layout } from '../../components/Layout/Layout';
import { useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import { useAppSelector } from '../../hooks';
import { selectUser } from '../Login/LoginSlice';
import { t } from 'i18next';
import { ProfilePicture } from '../../components/ProfilePicture/ProfilePicture';
import { getSortedAccounts } from './helpers';

export enum RecipientType {
  Groups = 'Groups',
  Users = 'Users',
  Accounts = 'Accounts',
  Contacts = 'Contacts',
}

interface RecipientGroupsProps {
  havePrebuiltLayout?: boolean;
}

export interface RecipientListItem {
  id: number;
  name: string;
  subtitle?: string;
  imagePath?: string;
  parentId?: number;
}

interface RecipientGroupsState {
  state: {
    data: RecipientListItem[];
    recipientsType?: RecipientType;
    prevPath?: string;
  };
}

export const RecipientGroups = ({ havePrebuiltLayout = false }: RecipientGroupsProps) => {
  const location = useLocation() as RecipientGroupsState;
  const { data = [], recipientsType, prevPath } = location.state;

  const haveImage = [RecipientType.Groups, RecipientType.Users].includes(
    recipientsType as RecipientType
  );
  const authUser = useAppSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<RecipientListItem[]>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    initialLoad();
  }, []);

  const getDisplayedName = (item: RecipientListItem) => {
    if (recipientsType === RecipientType.Users && authUser?.id === item.id) {
      return t('you');
    }

    return item.name;
  };

  const initialLoad = async () => {
    try {
      setLoading(true);
      let itemsList = [...data];
      if (recipientsType === RecipientType.Groups) {
        itemsList.sort((a, b) => a.name?.localeCompare(b?.name));
      } else if (recipientsType === RecipientType.Users) {
        const authUserProfile = [...itemsList].find(
          user => user.id === authUser?.id
        ) as RecipientListItem;
        const otherUsers = [...itemsList].filter(user => user.id !== authUser?.id);
        otherUsers.sort((a, b) => a.name.localeCompare(b.name));
        itemsList = [authUserProfile, ...otherUsers].filter(user => !!user);
      } else if (recipientsType === RecipientType.Accounts) {
        itemsList = getSortedAccounts(itemsList as RecipientListItem[]);
      } else if (recipientsType === RecipientType.Contacts) {
        itemsList.sort((a, b) => a.name?.localeCompare(b?.name));
      }
      setItems(itemsList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderComponent = (component: JSX.Element): JSX.Element => {
    const titleByType = {
      [RecipientType.Groups]: 'messages_recipient_groups',
      [RecipientType.Users]: 'messages_recipient_users',
      [RecipientType.Accounts]: 'messages_recipient_accounts',
      [RecipientType.Contacts]: 'messages_recipient_contacts',
    };
    if (havePrebuiltLayout) {
      return component;
    } else {
      return (
        <Layout
          backBtn
          to={prevPath}
          isMessageLayout
          message={
            recipientsType
              ? titleByType[recipientsType] ?? titleByType[RecipientType.Groups]
              : undefined
          }
        >
          <SPage>{component}</SPage>
        </Layout>
      );
    }
  };

  return renderComponent(
    <div>
      {loading && <Loader />}
      {!loading && (
        <SContainer>
          {[...items].map((item: RecipientListItem, index: number) => (
            <SRecipientContainer isLastItem={index === items.length - 1} key={item.id}>
              {haveImage && (
                <SProfilePictureContainer>
                  <ProfilePicture diameter={38} profilePictureFileName={item.imagePath ?? null} />
                </SProfilePictureContainer>
              )}

              <div className="textContainer">
                <p className="title">{getDisplayedName(item)}</p>
                {!!item.subtitle && <p className="subtitle">{item.subtitle}</p>}
              </div>
            </SRecipientContainer>
          ))}
        </SContainer>
      )}
    </div>
  );
};
