import React, {
  cloneElement,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { Header } from './Header/Header';
import { UnauthorizedHeader } from './Header/UnauthorizedHeader';
import Footer from './Footer/Footer';
import { LayoutContextProvider } from '../../utils/customHooks/LayoutContext';
import ActiveAlarmsModal from '../ActiveAlarmsModal/ActiveAlarmsModal';
import { MainWrapper, SMain } from './Layout.styles';

export interface ILayoutProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  isMessageLayout: boolean;
  message: string;
  noTranslate?: boolean;
  to?: string;
  backBtn?: boolean;
  isAuthLayout?: boolean;
  showCoSafeLogo?: boolean;
  showBottomTabs?: boolean;
  subMessageText?: string;
  showDots?: boolean;
  dotsCallBack?: () => void;
  handleHeaderContentClick?: () => void;
  isAlarmActive?: boolean;
  className?: string | undefined;
  backBtnCallBack?: (e: any) => void;
  backgroundColor?: string | undefined;
  isAlarmCreateLayout?: boolean;
  renderCustomRightButton?: () => ReactNode;
}

export const Layout = (props: ILayoutProps) => {
  const {
    children,
    message,
    isMessageLayout,
    to,
    backBtn,
    isAuthLayout = true,
    showCoSafeLogo = false,
    showBottomTabs = false,
    noTranslate = false,
    subMessageText,
    showDots = false,
    dotsCallBack,
    handleHeaderContentClick,
    isAlarmActive,
    className,
    backBtnCallBack,
    backgroundColor,
    isAlarmCreateLayout = false,
    renderCustomRightButton,
  } = props;

  const [tabsState, setTabsState] = useState<boolean>(showBottomTabs);
  const [backLink, setBackLink] = useState<string | undefined>(to);
  const [title, setTitle] = useState<string | undefined>(message);
  const [subTitle, setSubTitle] = useState<string | undefined>(subMessageText);
  const [doShowDots, setDoShowDots] = useState<boolean | undefined>(showDots);
  const [headerMenu, setHeaderMenu] = useState<boolean>(false);
  const [mapOpen, setMapOpen] = useState(false);

  useEffect(() => {
    if (to) setBackLink(to);
  }, [to]);

  useLayoutEffect(() => {
    setTitle(message);
  }, [message]);

  useLayoutEffect(() => {
    setSubTitle(subMessageText);
  }, [subMessageText]);
  useLayoutEffect(() => {
    setDoShowDots(showDots);
  }, [showDots]);

  const handleDotsMenu = () => {
    setHeaderMenu(!headerMenu);
    dotsCallBack?.();
  };

  return (
    <React.Fragment>
      {!isAuthLayout && <UnauthorizedHeader to={to ?? backLink} backBtn={backBtn} />}
      {isAuthLayout && (
        <Header
          backBtnCallBack={backBtnCallBack}
          to={backLink ?? to}
          isMessageLayout={isMessageLayout}
          message={title ?? message}
          noTranslate={noTranslate}
          backBtn={backBtn}
          showCoSafeLogo={showCoSafeLogo}
          subMessageText={subMessageText ?? subTitle}
          showDots={doShowDots ?? showDots}
          dotsCallBack={handleDotsMenu}
          handleHeaderContentClick={handleHeaderContentClick}
          isAlarmActive={isAlarmActive!}
          renderCustomRightButton={renderCustomRightButton}
        />
      )}
      <LayoutContextProvider
        setTabsState={setTabsState}
        setBackLink={setBackLink}
        setMessage={setTitle}
        setSubTitle={setSubTitle}
        setDoShowDots={setDoShowDots}
        setHeaderMenu={setHeaderMenu}
        isMenuOpen={headerMenu}
        isMapOpen={mapOpen}
        setMapOpen={setMapOpen}
        currentTitle={title ?? ''}
      >
        <MainWrapper>
          <SMain
            className={className}
            extraFooterPadding={tabsState}
            backgroundColor={backgroundColor}
          >
            {cloneElement(children, { setShowBottomTabs: setTabsState })}
          </SMain>
        </MainWrapper>
      </LayoutContextProvider>
      {<Footer showBottomTabs={tabsState} />}
      {isAlarmCreateLayout && <ActiveAlarmsModal />}
    </React.Fragment>
  );
};

Layout.defaultProps = {
  isMessageLayout: false,
  message: null,
};
