import { createContext, useContext, useMemo, useState } from 'react';
import { sessionStorageRemove } from '../../utils/storage';

interface StartIamOkMessageCtxState {
  groupIds: number | undefined;
  userIds: Set<number>;

  setGroupIds: React.Dispatch<React.SetStateAction<number | undefined>>;
  setUserIds: (set: Set<number>) => void;

  iamOkMessageText: string;
  setIamOkMessageText: (text: string) => void;

  subject: string;
  setSubject: React.Dispatch<React.SetStateAction<string>>;

  onFinish: () => void;
}

const StartIamOkMessageCtxDefaultValue: StartIamOkMessageCtxState = {
  groupIds: undefined,
  userIds: new Set(),

  setGroupIds: () => {},
  setUserIds: () => {},

  iamOkMessageText: '',
  setIamOkMessageText: () => {},

  subject: '',
  setSubject: () => {},

  onFinish: () => {},
};

const StartIamOkMessageCtx = createContext<StartIamOkMessageCtxState>(
  StartIamOkMessageCtxDefaultValue
);

export const TEXT_LENGTH_LIMIT = 300;

export const useStartIamOkMessageCtx = () => useContext(StartIamOkMessageCtx);

const StartIamOkMessageCtxKey = 'START_iamOk_MESSAGE';

interface StartIamOkMessageCtxProviderProps {
  children: React.ReactNode;
}
export const StartIamOkMessageCtxProvider = (props: StartIamOkMessageCtxProviderProps) => {
  const { children } = props;

  const [groupIds, setGroupIds] = useState<number>();

  const [userIds, setUserIds]: [Set<number>, (set: Set<number>) => void] = useState(new Set());

  const [iamOkMessageText, setIamOkMessageText] = useState<string>('');

  const [subject, setSubject] = useState<string>('');

  const setMessageText = (text: string) => {
    if (text.length <= TEXT_LENGTH_LIMIT) {
      setIamOkMessageText(text);
    }
  };

  const onFinish = () => {
    sessionStorageRemove(StartIamOkMessageCtxKey);
  };

  const value = useMemo(
    () => ({
      groupIds,
      userIds,
      subject,

      setGroupIds,
      setUserIds,
      setSubject,

      iamOkMessageText,
      setIamOkMessageText: setMessageText,

      onFinish,
    }),
    [groupIds, subject, iamOkMessageText, userIds]
  );
  return <StartIamOkMessageCtx.Provider value={value}>{children}</StartIamOkMessageCtx.Provider>;
};
