import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { CreateHoldingStatement } from './CreateHoldingStatement';
import { CreateHoldingStatementCtxProvider } from './CreateHoldingStatementContext';
import { CreateHoldingStatmentSummary } from './CreateHoldingStatmentSummary';
import { RecipientGroups } from '../RecipientGroups/RecipientGroups';
import { recipientGroupsCss } from '../RecipientGroups/RecipientGroups.styles';

const SPage = styled(Page)<{ $isRecipientGroups?: boolean }>`
  padding: 1.25rem 0 0 0;
  /* to make only the list overflow */
  position: relative;
  height: 100%;
  ${props => props.$isRecipientGroups && recipientGroupsCss}
`;

export const CreateHoldingStatementContainer = () => {
  const { pathname } = useLocation();
  const { setTabsState, toggleTabBarVisibility } = useLayoutContext();

  React.useEffect(() => {
    if (pathname === '/createHoldingStatement/summary') {
      toggleTabBarVisibility();
    } else {
      setTabsState(true);
    }
  }, [pathname]);

  return (
    <SPage $isRecipientGroups={pathname === '/createHoldingStatement/recipient-groups'}>
      <CreateHoldingStatementCtxProvider>
        <Routes>
          <Route path="/" element={<CreateHoldingStatement />} />
          <Route path="summary" element={<CreateHoldingStatmentSummary />} />
          <Route path="recipient-groups" element={<RecipientGroups havePrebuiltLayout />} />
        </Routes>
      </CreateHoldingStatementCtxProvider>
    </SPage>
  );
};
