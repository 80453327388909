import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SImage = styled.img`
  cursor: pointer;
  padding-left: ${toRem(4)};
`;

export const GreyOutlinedBtn = styled.span`
  font-family: 'Roboto-Medium';
  font-size: ${toRem(14)};
  line-height: ${toRem(20)};
  color: ${palette.white};
`;

export const RecipientButtonWrapper = styled.button`
  background-color: ${palette.prussianBlue2};
  outline: 0;
  border: 0;
  min-height: ${toRem(30)};
  border-radius: 8px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${toRem(6)};
  margin-bottom: ${toRem(8)};
`;
