import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SPage = styled(Page)`
  height: 100%;
  padding: 1.25rem 0 0 0;
  display: flex;
  flex-direction: column;
`;

export const SChatDetails = styled.div`
  padding: 0 1.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  margin-left: 0.75rem;
  min-height: 2.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  &.alignItemsCenter {
    align-items: center;
  }
  &.spaceBetween {
    justify-content: space-between;
  }
`;

export const STitle = styled.p`
  margin-top: ${toRem(3.5)};
  padding-left: 0.5rem;
  color: ${palette.silver};
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

export const STitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .availabilityChip {
    margin-top: ${toRem(6)};
  }
`;

export const SName = styled.p`
  color: ${palette.white};
  font-family: 'Roboto-Medium';
  font-size: 14px;
  line-height: 18px;
`;

export const SLine = styled.hr`
  margin: 0.75rem 0;
  height: 1px;
  border: none;
  background-color: ${palette.prussianBlue4};
  border-radius: 49px;
`;

export const SSeenContainer = styled.div`
  display: flex;
  align-items: center;

  .checkContainer {
    position: relative;
    width: 1rem;
    height: 11px;
    display: flex;
    align-items: flex-end;

    svg {
      position: absolute;

      :first-child {
        left: 4px;
      }
    }
  }
`;

export const AddBtn = styled(Link)`
  cursor: pointer;
  background-color: ${palette.honeyYellow};
  height: 32px;
  width: 32px;
  border-radius: 16px;
  margin-right: 0.5rem;
  align-content: center;
  justify-content: center;
  border: none;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

export const SText = styled.p`
  margin: 1.25rem 0;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

export const SSeen = styled.p<{ seen: boolean }>`
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${props => (props.seen ? palette.applGreen : palette.silver)};
  margin-right: 0.5rem;
`;

export const SRecipientsList = styled.ul`
  min-height: 0;
  height: 100%;
  padding-bottom: 6rem;

  /* vertical scrolling + hide scroller bar  */
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SRecipient = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SEnded = styled.div`
  background-color: ${palette.stormGray};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
`;
