import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks';
import Loader from '../../components/Loader/Loader';
import { getIsLoading, getOrgs, getSelectedOrgs } from './broadcastSlice';
import { fetchOrganizations, setSelectedOrgsAction } from './broadcastSlice/actionCreators';
import { SelectOrgItem } from './SelectOrgItem';
import { translate } from '../../utils/translate';
import { OrgList } from './SelectOrgList.styles';

export const SelectOrgsList = () => {
  const dispatch = useDispatch();
  const orgs = useAppSelector(getOrgs);
  const isLoading = useAppSelector(getIsLoading);
  const selectedOrgs = useAppSelector(getSelectedOrgs);
  const [orgList, setOrgList] = useState<any[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  useEffect(() => {
    setIsSelectAll(false);
    dispatch(fetchOrganizations());
  }, [dispatch]);

  useEffect(() => {
    const sortedOrgs = orgs
      .filter(o => !!o.name)
      .sort((a, b) => (!a.parentId || b.parentId) && a.name.localeCompare(b.name));
    setOrgList(sortedOrgs);
  }, [orgs]);

  useEffect(() => {
    if (orgs.length > 0 && selectedOrgs.length === orgs.length) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  }, [selectedOrgs]);

  const onOrgClick = (selectedOrg: { id: number; name: string; parentId?: string | null }) => {
    setIsSelectAll(false);
    const foundIndex = selectedOrgs.findIndex(org => org.id === selectedOrg.id);
    const tempArr = [...selectedOrgs];
    if (foundIndex > -1) {
      tempArr.splice(foundIndex, 1);
    } else {
      tempArr.push(selectedOrg);
    }
    dispatch(setSelectedOrgsAction(tempArr));
  };

  const isSelectedUser = (selectedOrganizations: any[], id: number) => {
    for (let i = 0; i < selectedOrgs.length; i++) {
      if (selectedOrganizations[i].id === id) {
        return i > -1;
      }
    }
    return false;
  };

  const unselectAllOrgs = () => {
    dispatch(setSelectedOrgsAction([]));
  };

  const selectAllOrgs = () => {
    const allOrgs = orgList.map(org => ({ id: org.id, name: org.name, parentId: org.parentId }));
    dispatch(setSelectedOrgsAction(allOrgs));
  };

  const toggleSelectAll = () => {
    if (!isSelectAll) {
      selectAllOrgs();
    } else {
      unselectAllOrgs();
    }
    setIsSelectAll(!isSelectAll);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <OrgList height={'69vh'} role="orgsList">
      <SelectOrgItem
        key={-1}
        org={{ id: -1, name: `${translate('org_list_select_all')}`, title: '' }}
        isSelected={isSelectAll}
        onCardPress={() => toggleSelectAll()}
        textClassName="selectAll"
      />
      {orgList.map(org => {
        return (
          <SelectOrgItem
            key={org.id}
            org={org}
            isSelected={isSelectedUser(selectedOrgs, org.id)}
            onCardPress={() => onOrgClick({ id: org.id, name: org.name, parentId: org.parentId })}
          />
        );
      })}
    </OrgList>
  );
};
