import isElectron from 'is-electron';
import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { isUserAgentMobile } from '../../utils/userAgent';

export const MainWrapper = styled.div<any>`
  width: 100%;
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : isElectron()
      ? props.theme.palette.background.primary
      : palette.darkblack};
`;

export const SMain = styled.main<any>`
  height: 100vh;
  min-height: 100vh;
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    /* height: 100%;
    min-height: 100vh; */
  }
  background-color: ${props => props.backgroundColor ?? props.theme.palette.background.primary};
  /* header height  */
  padding-top: 4.81rem;
  /* footer height  */
  padding-bottom: ${props => (props.extraFooterPadding ? '4.81rem' : 0)};
  max-width: 26rem;
  margin: auto;
  ${isUserAgentMobile &&
  `
    height: unset;
    max-height: unset;
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;

    box-sizing: border-box;
    overflow-y: hidden;
  `}
`;
