import { compact, isArray } from 'lodash';
import type { NavigateFunction } from 'react-router-dom';
import { FilesAndFoldersOptionsInfo } from '../../components/FilesAndFoldersOptions/FilesAndFoldersOptions';
import { OptionItemProps } from '../../components/Options/Options';
import { ConfirmationOptions } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';
import { translate } from '../../utils/translate';
import { DocumentGroup, FileItem, FolderItem } from '../Documents/documentsSlice/types';
import { Group } from '../GroupsList/groupsSlice/types';
import { BrowseFolderOptionsStateType, SelectedDocument } from './BrowseFolder';
import SetOFFLineIcon from '../../assets/imgs/documents/document-set-offline.svg';
import EditAccess from '../../assets/imgs/documents/document-edit-access.svg';
import Delete from '../../assets/imgs/documents/document-delete.svg';
import Edit from '../../assets/imgs/documents/document-edit.svg';
import {
  deleteFile,
  deleteFolder,
  toggleFileOrFolderOffline,
  renameFolder as renameFolderAction,
} from '../Documents/documentsSlice/actionCreators';
import { AppDispatch } from '../../store';
import { isAtLeastOneInstanceFileOffline, isDocumentOffline } from '../Documents/helpers';
import { ToggleOfflineModel } from '../../apis/documentsAPI/types';
import { RefObject } from 'react';
import { OperationListSelectModalRef } from '../../components/OperationListSelectModal/OperationListSelectModal';
import { EnhancedFolderItem } from '../Documents/Documents';

function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getFileOrFolderInfoById = (
  files: FileItem[],
  folders: FolderItem[],
  groupFolder: Group,
  selected: SelectedDocument,
  groupId?: number,
  folderId?: number
): FilesAndFoldersOptionsInfo => {
  let info: FilesAndFoldersOptionsInfo = {
    created: '',
    creator: '',
    sharedWith: [''],
    size: '',
    title: '',
    type: '',
  };
  if (selected.type === 'FILE') {
    const selectedFile = files.find(file => file.id === selected.id);
    if (selectedFile) {
      info = {
        created: getNumbersBasedDateTextByLanguage({ dateText: selectedFile.upload_time }),
        creator: selectedFile.userName || translate('documents_not_specified'),
        sharedWith: [
          ...compact(selectedFile.groups.map(g => g.name)),
          ...compact(selectedFile.folders.map(f => f.Name)),
        ],
        size: formatBytes(selectedFile.file_size),
        title: selectedFile.original_filen_name,
        type: isDocumentOffline(selectedFile, groupId, folderId)
          ? translate('documents_offline')
          : translate('documents_online'),
      };
    } else {
      console.error("Can't find the selected file");
    }
  }
  if (selected.type === 'FOLDER') {
    const selectedFolder = folders.find(folder => folder.ID === selected.id);
    const filesOdSelectedFolder = files.filter(file => file.folderids.includes(selected.id));

    const filesSize = filesOdSelectedFolder
      .map(file => file.file_size)
      .reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);

    const filesInFolderLength = files.filter(file => file.folderids.includes(selected.id)).length;

    if (selectedFolder) {
      info = {
        created: getNumbersBasedDateTextByLanguage({ dateText: selectedFolder?.creationTime }),
        creator: selectedFolder?.userName || '',
        sharedWith: [groupFolder.name],
        size: formatBytes(filesSize),
        title: selectedFolder?.Name || translate('documents_not_specified'),
        type: selectedFolder?.Offline
          ? translate('documents_offline')
          : translate('documents_online'),
        insideCount: filesInFolderLength,
      };
    } else {
      console.error("Can't find the selected folder ");
    }
  }
  return info;
};

export const isFolderAndReturn = (folder: FileItem | FolderItem): FolderItem | undefined => {
  return !!(folder as FolderItem).GroupID ? (folder as FolderItem) : undefined;
};

export const getFileOrFolderInfoByIdV2 = (
  files: FileItem[],
  selected: FileItem | FolderItem
): FilesAndFoldersOptionsInfo => {
  let info: FilesAndFoldersOptionsInfo = {
    created: '',
    creator: '',
    sharedWith: [''],
    size: '',
    title: '',
    type: '',
  };

  const selectedFolder = isFolderAndReturn(selected);
  if (selectedFolder) {
    const filesOdSelectedFolder = files.filter(file => file.folderids.includes(selectedFolder.ID));

    const filesSize = filesOdSelectedFolder
      .map(file => file.file_size)
      .reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);

    const filesInFolderLength = files.filter(file =>
      file.folderids.includes(selectedFolder.ID)
    ).length;
    info = {
      created: getNumbersBasedDateTextByLanguage({ dateText: selectedFolder?.creationTime }),
      creator: selectedFolder?.userName || '',
      sharedWith: [selectedFolder.groupName],
      size: formatBytes(filesSize),
      title: selectedFolder?.Name || translate('documents_not_specified'),
      type: selectedFolder?.Offline
        ? translate('documents_offline')
        : translate('documents_online'),
      insideCount: filesInFolderLength,
    };
  } else {
    const selectedFile = selected as FileItem;
    if (selectedFile)
      info = {
        created: getNumbersBasedDateTextByLanguage({ dateText: selectedFile.upload_time }),
        creator: selectedFile.userName || translate('documents_not_specified'),
        sharedWith: [
          ...compact(selectedFile.groups.map(g => g.name)),
          ...compact(selectedFile.folders.map(f => f.Name)),
        ],
        size: formatBytes(selectedFile.file_size),
        title: selectedFile.original_filen_name,
        type: isAtLeastOneInstanceFileOffline(selectedFile)
          ? translate('documents_offline')
          : translate('documents_online'),
      };
  }

  return info;
};

export const buildToggleOffline =
  (
    toggleOfflineModel: ToggleOfflineModel | ToggleOfflineModel[],
    isOffline: boolean,
    confirm: (options: ConfirmationOptions) => Promise<void>,
    toggleIsOpen: () => void,
    dispatch: AppDispatch,
    withoutConfirm = false
  ) =>
  () => {
    const isOfflineModalArray = isArray(toggleOfflineModel);
    const toggleOfflineModels = (
      isOfflineModalArray ? toggleOfflineModel : [toggleOfflineModel]
    ) as ToggleOfflineModel[];
    if (withoutConfirm) {
      dispatch(toggleFileOrFolderOffline({ toggleOfflineModels, confirm }));
      toggleIsOpen();
    } else {
      confirm({
        title: 'messages_confirmation',
        description: isOffline
          ? 'documents_document_unset_offline_intro'
          : 'documents_document_set_offline_intro',
        onSubmit: () => {
          dispatch(toggleFileOrFolderOffline({ toggleOfflineModels, confirm }));
          toggleIsOpen();
        },
        onCancel: () => {},
        confirmText: isOffline ? 'documents_unset' : 'documents_setOffline',
      });
    }
  };

export const buildRenameFolder =
  (
    selectedId: number,
    currentName: string,
    confirm: (options: ConfirmationOptions) => Promise<void>,
    toggleIsOpen: () => void,
    dispatch: AppDispatch
  ) =>
  () => {
    confirm({
      title: 'documents_name_folder',
      onSubmit: text => {
        dispatch(
          renameFolderAction({
            params: { id: selectedId, name: text || '' },
            confirm,
          })
        );
        toggleIsOpen();
      },
      onCancel: () => {},
      confirmText: 'messages_proceed',
      inputBox: true,
      inputBoxIntialValue: currentName,
      placeholderTx: 'documents_folder_name',
    });
  };

export const buildDeleteFolderOrFile =
  (
    selected: SelectedDocument,
    confirm: (options: ConfirmationOptions) => Promise<void>,
    dispatch: AppDispatch,
    toggleIsOpen: () => void,
    fileDelete?: { groupIds?: number[]; folderIds?: number[] },
    withoutConfirm = false
  ) =>
  () => {
    if (selected.type === 'FOLDER') {
      if (withoutConfirm) {
        dispatch(deleteFolder(selected.id));
        toggleIsOpen();
      } else {
        confirm({
          title: 'messages_confirmation',
          description: 'documents_delete_document_confirm',
          onSubmit: () => {
            dispatch(deleteFolder(selected.id));
            toggleIsOpen();
          },
          onCancel: () => {},
          confirmText: 'delete',
          confirmStyle: 'red',
        });
      }
    }
    if (selected.type === 'FILE') {
      if (withoutConfirm) {
        if (fileDelete)
          dispatch(
            deleteFile({
              id: selected.id,
              folderIds: fileDelete.folderIds,
              groupIds: fileDelete.groupIds,
            })
          );
        toggleIsOpen();
      } else {
        confirm({
          title: 'messages_confirmation',
          description: 'documents_delete_document_confirm',
          onSubmit: () => {
            if (fileDelete)
              dispatch(
                deleteFile({
                  id: selected.id,
                  folderIds: fileDelete.folderIds,
                  groupIds: fileDelete.groupIds,
                })
              );
            toggleIsOpen();
          },
          onCancel: () => {},
          confirmText: 'delete',
          confirmStyle: 'red',
        });
      }
    }
  };

export const buildEditFileAccess = (navigation: NavigateFunction, selectedId: number) => () => {
  // folder/file here is a mock, not needed in the edit file access process but was added to
  // to enable react router to correctly redirect it to the correct path (and to enable the old approach to work)
  navigation(`/documents/folder/file/editAccess/${selectedId}`);
};

export interface GenerateOptionsProps {
  dependencies: {
    confirm: (options: ConfirmationOptions) => Promise<void>;
    navigation: NavigateFunction;
    setOptionsState: React.Dispatch<React.SetStateAction<BrowseFolderOptionsStateType>>;
    groupFolderId: number;
    browseFolderId?: number;
    dispatch: AppDispatch;
  };
  files: FileItem[];
  folders: FolderItem[];
  selected: SelectedDocument;
  isDocumentsManager: boolean;
}
export const generateOptions = (props: GenerateOptionsProps) => {
  const { dependencies, files, folders, selected, isDocumentsManager } = props;
  const { confirm, navigation, setOptionsState, groupFolderId, browseFolderId, dispatch } =
    dependencies;

  const toggleIsOpen = () => {
    setOptionsState(prev => ({ ...prev, isOpen: !prev.isOpen }));
  };

  if (isDocumentsManager) {
    if (selected.type === 'FOLDER') {
      const selectedFolder = folders.find(folder => folder.ID === selected.id);
      const isOffline = selectedFolder?.Offline;

      const options: OptionItemProps[] = [
        {
          callback: buildToggleOffline(
            { offline: !isOffline, folderID: selected.id },
            !!isOffline,
            confirm,
            toggleIsOpen,
            dispatch
          ),
          icon: SetOFFLineIcon,
          name: isOffline ? 'documents_unset_offline_status' : 'documents_set_mandatory_offline',
        },
        {
          callback: buildRenameFolder(
            selected.id,
            selectedFolder?.Name || '',
            confirm,
            toggleIsOpen,
            dispatch
          ),
          icon: Edit,
          name: 'documents_rename_folder',
        },
        {
          callback: buildDeleteFolderOrFile(selected, confirm, dispatch, toggleIsOpen),
          icon: Delete,
          name: 'messages_delete',
        },
      ];
      setOptionsState(prev => ({ ...prev, isOpen: true, options }));
    }
    const selectedFile = files.find(file => file.id === selected.id);
    if (selected.type === 'FILE' && selectedFile) {
      const fileGroupIds = selectedFile?.groups.map(g => g.GroupID);
      const fileFolderIds = selectedFile?.folderids;
      const isOffline = isDocumentOffline(selectedFile, groupFolderId, browseFolderId);

      const options: OptionItemProps[] = [
        {
          callback: buildToggleOffline(
            browseFolderId
              ? {
                  offline: !isOffline,
                  documentID: selected.id,
                  folderID: browseFolderId,
                }
              : {
                  offline: !isOffline,
                  documentID: selected.id,
                  groupID: groupFolderId,
                },
            !!isOffline,
            confirm,
            toggleIsOpen,
            dispatch
          ),
          icon: SetOFFLineIcon,
          name: !isOffline ? 'documents_set_mandatory_offline' : 'documents_unset_offline_status',
        },
        {
          callback: buildEditFileAccess(navigation, selected.id),
          icon: EditAccess,
          name: 'checklist_edit_acces',
        },
        {
          callback: buildDeleteFolderOrFile(selected, confirm, dispatch, toggleIsOpen, {
            folderIds: fileFolderIds,
            groupIds: fileGroupIds,
          }),
          icon: Delete,
          name: 'messages_delete',
        },
      ];
      setOptionsState(prev => ({ ...prev, isOpen: true, options }));
    }
  } else {
    setOptionsState(prev => ({ ...prev, options: [] }));
  }
};

export interface GenerateOptionsPropsV2 {
  dependencies: {
    confirm: (options: ConfirmationOptions) => Promise<void>;
    navigation: NavigateFunction;
    setOptionsState: React.Dispatch<React.SetStateAction<BrowseFolderOptionsStateType>>;

    extraOperationRef?: RefObject<OperationListSelectModalRef<EnhancedFolderItem | DocumentGroup>>;
    dispatch: AppDispatch;
  };
  selected: FileItem | FolderItem;
  isDocumentsManager: boolean;
}
export const generateOptionsV2 = (props: GenerateOptionsPropsV2) => {
  const { dependencies, selected, isDocumentsManager } = props;
  const { confirm, navigation, setOptionsState, dispatch, extraOperationRef } = dependencies;

  const selectedFolder = isFolderAndReturn(selected);

  const toggleIsOpen = () => {
    setOptionsState(prev => ({ ...prev, isOpen: !prev.isOpen }));
  };

  if (isDocumentsManager) {
    if (selectedFolder) {
      const isOffline = selectedFolder?.Offline;

      const options: OptionItemProps[] = [
        {
          callback: buildToggleOffline(
            { offline: !isOffline, folderID: selectedFolder.ID },
            !!isOffline,
            confirm,
            toggleIsOpen,
            dispatch
          ),
          icon: SetOFFLineIcon,
          name: isOffline ? 'documents_unset_offline_status' : 'documents_set_mandatory_offline',
        },
        {
          callback: buildRenameFolder(
            selectedFolder.ID,
            selectedFolder?.Name,
            confirm,
            toggleIsOpen,
            dispatch
          ),
          icon: Edit,
          name: 'documents_rename_folder',
        },
        {
          callback: buildDeleteFolderOrFile(
            { id: selectedFolder.ID, type: 'FOLDER' },
            confirm,
            dispatch,
            toggleIsOpen
          ),
          icon: Delete,
          name: 'messages_delete',
        },
      ];
      setOptionsState(prev => ({ ...prev, isOpen: true, options }));
    } else {
      const selectedFile = selected as FileItem;
      // groups and folders which the file is available offline or online
      const offlineGroups: DocumentGroup[] = [];
      const onlineGroups: DocumentGroup[] = [];
      // groups and folders which the file is only online in
      const offlineFolders: EnhancedFolderItem[] = [];
      const onlineFolders: EnhancedFolderItem[] = [];

      const fileFolders = selectedFile?.folders.map(folder => ({
        ...folder,
        id: folder.ID,
        name: folder.Name,
        subName: folder.groupName,
      }));
      const fileGroups = selectedFile?.groups;
      fileFolders.forEach(f => {
        if (f.Offline) {
          offlineFolders.push(f);
        } else {
          onlineFolders.push(f);
        }
      });
      fileGroups.forEach(g => {
        if (g.offline) {
          offlineGroups.push(g);
        } else {
          onlineGroups.push(g);
        }
      });
      // PM decision: we will not display folders options in this list
      // we will depend that we can enable/disable offline use for this file inside
      // these folders itself instead
      const addSetOfflineOption = onlineGroups.length !== 0;
      const addSetOnlineOption = offlineGroups.length !== 0;

      const options: (OptionItemProps | undefined)[] = [
        {
          callback: buildEditFileAccess(navigation, selectedFile.id),
          icon: EditAccess,
          name: 'checklist_edit_acces',
        },
        addSetOfflineOption
          ? {
              callback: () => {
                extraOperationRef?.current?.open({
                  foldersData: [],
                  groupsData: onlineGroups,
                  preset: 'enableOffline',
                  onFinish: (folderIds, groupIds) => {
                    buildToggleOffline(
                      [
                        ...folderIds.map(folderId => ({
                          folderID: folderId,
                          documentID: selectedFile.id,
                          offline: true,
                        })),
                        ...groupIds.map(groupId => ({
                          groupId: groupId,
                          documentID: selectedFile.id,
                          offline: true,
                        })),
                      ],
                      false,
                      confirm,
                      toggleIsOpen,
                      dispatch,
                      true
                    )();
                  },
                });
              },
              icon: SetOFFLineIcon,
              name: 'documents_set_mandatory_offline',
            }
          : undefined,
        addSetOnlineOption
          ? {
              callback: () => {
                extraOperationRef?.current?.open({
                  foldersData: [],
                  groupsData: offlineGroups,
                  preset: 'disableOffline',
                  onFinish: (folderIds, groupIds) => {
                    buildToggleOffline(
                      [
                        ...folderIds.map(folderId => ({
                          folderID: folderId,
                          documentID: selectedFile.id,
                          offline: false,
                        })),
                        ...groupIds.map(groupId => ({
                          groupId: groupId,
                          documentID: selectedFile.id,
                          offline: false,
                        })),
                      ],
                      true,
                      confirm,
                      toggleIsOpen,
                      dispatch,
                      true
                    )();
                  },
                });
              },
              icon: SetOFFLineIcon,
              name: 'documents_unset_offline_status',
            }
          : undefined,
        // rename document option commented for now
        // this code should be used in the context of CST-11494 task
        // {
        //   callback: () => {
        //     extraOperationRef?.current?.open({
        //       foldersData: fileFolders,
        //       groupsData: fileGroups,
        //       preset: 'rename',
        //       onFinish: () => {
        //         console.log('Not implemented yet');
        //       }
        //     });
        //   },
        //   icon: Edit,
        //   name: 'documents_rename'
        // },
        {
          callback: () => {
            extraOperationRef?.current?.open({
              foldersData: fileFolders,
              groupsData: fileGroups,
              preset: 'delete',
              onFinish: (folderIds, groupIds) => {
                buildDeleteFolderOrFile(
                  { id: selectedFile.id, type: 'FILE' },
                  confirm,
                  dispatch,
                  toggleIsOpen,
                  {
                    folderIds,
                    groupIds,
                  },
                  true
                )();
              },
            });
          },
          icon: Delete,
          name: 'messages_delete',
        },
      ];
      setOptionsState(prev => ({ ...prev, isOpen: true, options: compact(options) }));
    }
  } else {
    setOptionsState(prev => ({
      ...prev,
      isOpen: true,
      options: [],
    }));
  }
};
