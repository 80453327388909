import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { getGroupByID } from '../../GroupsList/groupsSlice';
import { Group } from '../../GroupsList/groupsSlice/types';
import {
  Chat,
  ChatListState,
  MessagesRecipients,
  ReadReceipt,
  MessageListFilterGroup,
  TotalUnread,
  Reply,
  MessageInList,
  MessageReactionType,
  MessageReactions,
} from './types';
import { getLogNotes } from '../../LogNotes/helpers';
import { filterStatement } from '../../../utils/customHooks/useStatementFilterdAndPagination';

// initail state
const initialTotalUnread: TotalUnread = {
  ActiveEmergency: false,
  UnreadCount: null,
  UnreadMessagesAndRepliesCount: null,
  blockingAlarm: false,
  activeMusterCount: 0,
  ActiveChecklists: 0,
  personalAlarmDispatchTime: null,
  available: true,
};
const initialState: ChatListState = {
  chats: [],
  logNotes: [],
  holdingStatements: [],
  currentChat: undefined,
  currentChatReplies: [],
  currentReplyReadReceipt: undefined,
  totalUnread: initialTotalUnread,
  isLoading: false,
  areRepliesLoading: false,
  error: null,
  joinGroupError: null,
  messagesRecipients: [],
  searchPhrase: '',
  incomingMessage: false,
  incomingUpdateMessageID: undefined,
  chatFilters: [],
  filterGroups: [],
  areFilesSending: false,
  areAllRepliesLoaded: false,
  showLoadingReplies: false,
  scrollTopPosition: null,
  scrollToBottom: false,
  selectedReactionsMessageId: null,
  selectedMessageReactions: null,
  messageReactionTypes: [],
  isMessageReactionsLoading: false,
  latestThresholdLoadTime: null,
};

// group detail slice
const chatListSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setChats: (state, action: PayloadAction<MessageInList[]>) => {
      state.chats = action.payload;
    },
    setLogNotes: (state, action: PayloadAction<MessageInList[]>) => {
      state.logNotes = action.payload;
    },
    setHoldingStatements: (state, action: PayloadAction<MessageInList[]>) => {
      state.holdingStatements = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAreRepliesLoading: (state, action: PayloadAction<boolean>) => {
      state.areRepliesLoading = action.payload;
    },
    setIsError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setTotalUnread: (state, action: PayloadAction<TotalUnread>) => {
      state.totalUnread = action.payload;
    },
    setCurrentChat: (state, action: PayloadAction<Chat | undefined>) => {
      state.currentChat = action.payload;
    },
    setCurrentChatReplies: (state, action: PayloadAction<Reply[]>) => {
      state.currentChatReplies = action.payload;
    },
    setCurrentReplyReadReceipt: (state, action: PayloadAction<ReadReceipt | undefined>) => {
      state.currentReplyReadReceipt = action.payload;
    },
    setSearchPhrase: (state, action: PayloadAction<string>) => {
      state.searchPhrase = action.payload;
    },
    setIncomingMessages: (state, action: PayloadAction<boolean>) => {
      state.incomingMessage = action.payload;
    },
    setIncomingUpdateMessageID: (state, action: PayloadAction<number | undefined>) => {
      state.incomingUpdateMessageID = action.payload;
    },
    setChatFilters: (state, action: PayloadAction<number[]>) => {
      state.chatFilters = action.payload;
    },
    setFilterGroups: (state, action: PayloadAction<MessageListFilterGroup[]>) => {
      state.filterGroups = action.payload;
    },
    setMessagesRecipients: (state, action: PayloadAction<MessagesRecipients[]>) => {
      state.messagesRecipients = action.payload;
    },
    setAreFilesSending: (state, action: PayloadAction<boolean>) => {
      state.areFilesSending = action.payload;
    },
    setAreAllRepliesLoaded: (state, action: PayloadAction<boolean>) => {
      state.areAllRepliesLoaded = action.payload;
    },
    setShowLoadingReplies: (state, action: PayloadAction<boolean>) => {
      state.showLoadingReplies = action.payload;
    },
    setScrollTopPosition: (state, action: PayloadAction<number | null>) => {
      state.scrollTopPosition = action.payload;
    },
    setScrollToBottom: (state, action: PayloadAction<boolean>) => {
      state.scrollToBottom = action.payload;
    },
    setSelectedReactionsMessageId: (state, action: PayloadAction<number | null>) => {
      state.selectedReactionsMessageId = action.payload;
    },
    setMessageReactionTypes: (state, action: PayloadAction<MessageReactionType[]>) => {
      state.messageReactionTypes = action.payload;
    },
    setSelectedMessageReactions: (state, action: PayloadAction<MessageReactions | null>) => {
      state.selectedMessageReactions = action.payload;
    },
    setIsMessageReactionsLoading: (state, action: PayloadAction<boolean>) => {
      state.isMessageReactionsLoading = action.payload;
    },
    setLatestThresholdLoadTime: (state, action: PayloadAction<string | null>) => {
      state.latestThresholdLoadTime = action.payload;
    },
  },
});

// export store actions
export const {
  setIsLoading: setIsChatsLoading,
  setAreRepliesLoading,
  setShowLoadingReplies,
  setIsError,
  setChats,
  setCurrentChat,
  setCurrentChatReplies,
  setTotalUnread,
  setIncomingMessages,
  setIncomingUpdateMessageID,
  setSearchPhrase,
  setMessagesRecipients,
  setLogNotes,
  setHoldingStatements,
  setChatFilters,
  setCurrentReplyReadReceipt,
  setFilterGroups,
  setAreFilesSending,
  setAreAllRepliesLoaded,
  setScrollTopPosition,
  setScrollToBottom,
  setSelectedReactionsMessageId,
  setMessageReactionTypes,
  setSelectedMessageReactions,
  setIsMessageReactionsLoading,
  setLatestThresholdLoadTime,
} = chatListSlice.actions;

// data selecting could be seperated also
export const selectError = (state: RootState) => state.chatList.error;
export const selectChats = (state: RootState) => state.chatList.chats;
export const selectFilterGroups = (state: RootState) => state.chatList.filterGroups;
export const selectIsAvailable = (state: RootState) => state.chatList.totalUnread.available ?? true;
export const selectMessagesTotalUnread = (state: RootState) =>
  state.chatList.totalUnread.UnreadMessagesAndRepliesCount;
export const selectActiveMusterCount = (state: RootState) =>
  state.chatList.totalUnread.activeMusterCount;
export const selectHasBlockingAlarm = (state: RootState) =>
  state.chatList.totalUnread.blockingAlarm;
export const selectSearchPhrase = (state: RootState) => state.chatList.searchPhrase;
export const selectMessagesActiveEmergancy = (state: RootState) =>
  state.chatList.totalUnread.ActiveEmergency;
export const selectCurrentChat = (state: RootState) => state.chatList.currentChat;
export const selectCurrentChatReplies = (state: RootState) => state.chatList.currentChatReplies;
export const selectChatReply = (id: number) => (state: RootState) =>
  state.chatList.currentChatReplies.find(reply => reply.id === id);
export const selectCurrentReadReceipt = (state: RootState) =>
  state.chatList.currentReplyReadReceipt;
export const selectMessagesRecipients = (state: RootState) => state.chatList.messagesRecipients;
export const selectScrollTopPosition = (state: RootState) => state.chatList.scrollTopPosition;
export const selectScrollToBottom = (state: RootState) => state.chatList.scrollToBottom;

export const getActiveChatGroups = (state: RootState) => {
  const availableGroups: Group[] = [];
  state.chatList.chats.forEach(message => {
    const foundGroup = getGroupByID(Number(message.groupID), state);
    if (foundGroup && availableGroups.indexOf(foundGroup) === -1) {
      availableGroups.push(foundGroup);
    }
  });
  return availableGroups;
};

export const selectAreAllRepliesLoaded = (state: RootState) => state.chatList.areAllRepliesLoaded;
export const selectChatListFilters = (state: RootState) => state.chatList.chatFilters;
export const selectAreFilesSending = (state: RootState) => state.chatList.areFilesSending;
export const isChatsLoading = (state: RootState) => state.chatList.isLoading;
export const selectAreRepliesLoading = (state: RootState) => state.chatList.areRepliesLoading;
export const selectShowLoadingReplies = (state: RootState) => state.chatList.showLoadingReplies;

export const selectIsIncomingMessages = (state: RootState) => state.chatList.incomingMessage;
export const selectIncomingUpdateMessageID = (state: RootState) =>
  state.chatList.incomingUpdateMessageID;

export const selectLogNotes = (state: RootState) => state.chatList.logNotes;

export const selectLogNoteById = (id: number) => (state: RootState) =>
  getLogNotes(state.chatList.chats).find(chat => chat.id === id);

export const getActiveStatementGroups = (state: RootState) => {
  const availableGroups: Group[] = [];
  state.chatList.holdingStatements.forEach(message => {
    const foundGroup = getGroupByID(Number(message.groupID), state);
    if (foundGroup && availableGroups.indexOf(foundGroup) === -1) {
      availableGroups.push(foundGroup);
    }
  });
  return availableGroups;
};

export const selectHoldingStatementWithFilter =
  (filterOnlyGroupIds: number[] | undefined) => (state: RootState) =>
    filterStatement(
      state.chatList.holdingStatements,
      filterOnlyGroupIds,
      getActiveStatementGroups(state)
    );

export const selectHoldingStatement = (state: RootState) => state.chatList.holdingStatements;
export const selectedReactionsMessageId = (state: RootState) =>
  state.chatList.selectedReactionsMessageId;

export const selectMessageReactionTypes = (state: RootState) => {
  const types = [...state.chatList.messageReactionTypes];
  types.sort((a, b) => Number(a.order) - Number(b.order));
  return types;
};
export const selectReactionsListByMessageOrReply = (id: number) => (state: RootState) => {
  const reply = selectChatReply(id)(state);
  const chat = selectCurrentChat(state);
  const reactions = reply ? reply.reactions : chat?.reactions;
  const reactionTypes = [...state.chatList.messageReactionTypes];
  const reactionsList = reactions ?? [];
  const filteredReactions = [...reactionsList]?.filter(reaction => reaction.count > 0);
  filteredReactions.sort((a, b) => {
    const orderA = reactionTypes.find(item => item._Id === a.reactionTypeId)?.order as number;
    const orderB = reactionTypes.find(item => item._Id === b.reactionTypeId)?.order as number;
    return orderA - orderB;
  });

  return filteredReactions;
};

export const selectedMessageReactions = (state: RootState) =>
  state.chatList.selectedMessageReactions;
export const selectedIsMessageReactionsLoading = (state: RootState) =>
  state.chatList.isMessageReactionsLoading;

export default chatListSlice.reducer;
