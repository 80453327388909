import * as React from 'react';
import { ReactComponent as Add } from '../../assets/imgs/cec/cec-add.svg';
import { RecipientButton } from '../RecipientButton/RecipientButton';
import { Group, Group as GroupType } from '../../containers/GroupsList/groupsSlice/types';
import { useWindowSize } from '../../utils/customHooks/useWindowSize';
import { translate } from '../../utils/translate';
import { SMoreText } from '../../containers/CreateMessage/CreateMessageSummary.styles';
import { useNavigate } from 'react-router-dom';
import { RecipientType } from '../../containers/RecipientGroups/RecipientGroups';
import { SAddButton, SContainer } from './RecipientsSummaryList.styles';
import { getDisplayedItems } from './helpers';
import { GroupInMessageDetail, Org } from '../../containers/ChatsList/chatListSlice/types';
import { User } from '../../apis/authApi/types';
import { selectUser } from '../../containers/Login/LoginSlice';
import { useAppSelector } from '../../hooks';
import { ExternalContact } from '../../apis/externalContacts/types';
import { SelectListUser } from '../../containers/CreateMessage/createMessageSlice/types';
import { SelectedGroup } from '../../containers/CreateMessage/SelectGroupsList';

export type DataType =
  | Group
  | User
  | Org
  | GroupInMessageDetail
  | SelectListUser
  | SelectedGroup
  | ExternalContact;
export interface IRecipientsSummaryListProps {
  data: DataType[];
  nameProperty?: string;
  recipientsType?: RecipientType;
  maxCharsCount?: number;
  sortByProperty?: boolean;
  marginHorizontal?: number;
  recipientGroupsRoute?: string;
  handleAddClick?: () => void;
  handleRemoveClick?: (id: number) => void;
}

export const RecipientsSummaryList = ({
  data,
  nameProperty = 'name',
  recipientsType = RecipientType.Groups,
  maxCharsCount,
  sortByProperty,
  marginHorizontal = 20,
  recipientGroupsRoute = '/message/recipient-groups',
  handleAddClick,
  handleRemoveClick,
}: IRecipientsSummaryListProps) => {
  const navigate = useNavigate();
  const authUser = useAppSelector(selectUser);
  const [width] = useWindowSize();
  const haveAddButton = !!handleAddClick;
  const haveRemoveButton = !!handleRemoveClick;
  const [displayedItems, setDisplayedItems] = React.useState<GroupType[]>([]);

  const loadDisplayedItems = async () => {
    if (data.length > 1) {
      const loadedItems = await getDisplayedItems({
        itemsList: data as unknown as DataType[],
        nameProperty: nameProperty,
        width,
        maxCharsCount,
        sortByProperty,
        authUserId: recipientsType === RecipientType.Users ? authUser?.id : undefined,
        marginHorizontal,
        haveAddButton,
        haveRemoveButton,
      });
      if (loadedItems.length) {
        setDisplayedItems(loadedItems as unknown as GroupType[]);
        return;
      }
    }
    setDisplayedItems(data as unknown as GroupType[]);
  };

  React.useEffect(() => {
    loadDisplayedItems();
  }, [data, width]);

  const navigateToRecipientGroups = () => {
    if (!recipientGroupsRoute) return;
    navigate(recipientGroupsRoute, {
      state: {
        prevPath: location.pathname,
        data: data.map(item => ({
          id: item.id,
          name: item[nameProperty as keyof DataType] as unknown as string,
          imagePath:
            recipientsType === RecipientType.Groups
              ? (item as Group).imageFileName
              : recipientsType === RecipientType.Users
              ? (item as User).photoFileName
              : undefined,
          parentId: recipientsType === RecipientType.Accounts ? (item as Org).parentId : undefined,
          subtitle:
            recipientsType === RecipientType.Users && authUser?.id !== item.id
              ? (item as User).email
              : recipientsType === RecipientType.Accounts
              ? translate(
                  (item as Org).parentId
                    ? 'messages_broadcast_subaccount'
                    : 'messages_broadcast_account'
                )
              : undefined,
        })),
        recipientsType,
      },
    });
  };

  const isAuthUser = (item: DataType) => {
    return recipientsType === RecipientType.Users && authUser?.id === item?.id;
  };

  const getName = (item: DataType) => {
    if (isAuthUser(item)) return translate('you');
    return item[nameProperty as keyof DataType] as unknown as string;
  };

  return (
    <SContainer role="horizontalScrollContainer">
      {haveAddButton && (
        <SAddButton role="addButton" onClick={handleAddClick}>
          <Add role="addIcon" />
        </SAddButton>
      )}
      {displayedItems.map((listItem, index) => (
        <RecipientButton
          key={`${listItem.id}-${index}`}
          name={getName(listItem)}
          hideRemove={!haveRemoveButton || isAuthUser(listItem)}
          onClick={haveRemoveButton ? () => handleRemoveClick?.(listItem.id) : undefined}
        />
      ))}
      {data.length > displayedItems?.length && (
        <SMoreText onClick={() => navigateToRecipientGroups()}>{`+${
          data.length - displayedItems?.length
        } ${translate('more')}`}</SMoreText>
      )}
    </SContainer>
  );
};
