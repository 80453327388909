import { t } from 'i18next';
import { acceptedImageUploadFormats } from './global';

export const splitName = (name: string) => {
  const firstName = name.split(' ')[0];
  const lastName = name.split(' ')[1];

  return {
    firstName,
    lastName,
  };
};

export const isInViewport = (element: any): boolean => {
  if (element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  } else {
    return false;
  }
};

export const getUnsupportedImageUploadWarningText = () => {
  return t('unsupported_image_format_description', {
    supportedFormats: acceptedImageUploadFormats.map(format => `.${format}`).join(', '),
  });
};

export const removeNullAndUndefinedPropertiesFromObject = <T extends Record<string, unknown>>(
  obj: T
): T => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value != null)) as T;
};
