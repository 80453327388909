import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { editIamOkMessage } from '../../apis/imOkAPI';
import Pencil from '../../assets/imgs/iamokay/iamok-edit-colored.svg';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { translate } from '../../utils/translate';
import { selectImOkDocument, selectImOkIsLoading } from '../ImOkList/imOkSlice';
import { fetchImOkDocument } from '../ImOkList/imOkSlice/actionCreators';
import {
  SIcon,
  SInput,
  SItem,
  SPage,
  SSaveButton,
  STextArea,
  STitle,
} from './EditIamOkMessage.styles';
import { resizeTextArea } from '../OnCallAlertList/createMessageOnCallAlertSummary.helpers';

export const EditIamOkMessage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const confirm = useConfirmation();

  const imOkDocument = useAppSelector(selectImOkDocument);
  const ImOkIsLoading = useAppSelector(selectImOkIsLoading);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const textInputRef = React.useRef<HTMLInputElement>(null);

  const [subject, setSubject] = React.useState<string | undefined>(imOkDocument?.subject);
  const [message, setMessage] = React.useState<string>(imOkDocument?.name || '');

  React.useEffect(() => {
    textInputRef.current?.focus();
  }, []);

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  };
  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const fetchImOk = async () => {
    dispatch(fetchImOkDocument(id || ''));
  };

  const goToIamOk = (iamOkId: number) => {
    navigate(`/muster/${iamOkId}`);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      if (id) {
        await editIamOkMessage({
          id: +id,
          message: { name: message, subject },
        });
      }

      setIsLoading(false);
      fetchImOk();
      if (id) goToIamOk(+id);
    } catch (e) {
      const description = typeof e === 'string' ? e : translate('onCallAlert_changes_not_saved');
      setIsLoading(false);
      confirm({
        title: 'warning',
        description,
        onCancel: () => {},
        cancelText: 'cancel',
      });
    }
  };

  React.useEffect(() => {
    setSubject(imOkDocument?.subject);
    if (imOkDocument?.name) setMessage(imOkDocument?.name);
  }, [imOkDocument]);

  React.useEffect(() => {
    fetchImOk();
  }, []);

  React.useEffect(() => {
    if (textAreaRef.current) resizeTextArea(textAreaRef, 18);
  }, [message]);

  if (!imOkDocument && ImOkIsLoading && isLoading) return <Loader />;

  return (
    <SPage>
      <SItem className="input">
        <STitle>{translate(`messages_subject_add`)}</STitle>
        <SInput
          ref={textInputRef}
          onChange={handleSubjectChange}
          value={subject}
          type="text"
          placeholder={translate(`messages_subject_add_placeholder`)}
        />
        <SIcon src={Pencil} alt="" />
      </SItem>
      <SItem $textArea $padding={'0.6rem 0 0 0'}>
        <STitle>{translate(`imOk_message`)}:</STitle>
        <STextArea
          ref={textAreaRef}
          onChange={handleMessageChange}
          value={message}
          placeholder={translate(`imOk_message`)}
        />
        <SIcon src={Pencil} alt="" />
      </SItem>
      <SSaveButton tx="saveChanges" onClick={handleSave} />
    </SPage>
  );
};
