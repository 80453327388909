import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from '../components/Layout/Layout';
import { CreateHoldingStatementContainer } from '../containers/CreateHoldingStatement/CreateHoldingStatementContainer';

const backRoutes: { [index: string]: string } = {
  '/createHoldingStatement': '/holding-statement',
  '/createHoldingStatement/summary': '/createHoldingStatement',
  '/createHoldingStatement/recipient-groups': '/createHoldingStatement/summary',
};

export const CreateHoldingStatementPage = () => {
  const { pathname } = useLocation();
  const backRoute = useMemo(() => backRoutes[pathname], [pathname]);
  const screenTitle =
    '/createHoldingStatement/recipient-groups' === pathname
      ? 'messages_recipient_groups'
      : 'statement_create_screen';

  return (
    <Layout isMessageLayout message={screenTitle} to={backRoute} showBottomTabs>
      <CreateHoldingStatementContainer />
    </Layout>
  );
};
