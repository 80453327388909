import styled from 'styled-components';
import { BlockedStateBanner } from '../../components/MessageItem/BlockedStateBanner/BlockedStateBanner';
import { Page } from '../../components/Page/Page';

export const SSection = styled.section`
  padding: 1rem 7%;
  height: 100%;
`;

export const SBlockedStateBanner = styled(BlockedStateBanner)`
  margin: 1rem 7% 0 7%;
  p {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const SListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  row-gap: 1rem;
  padding-bottom: 1.3125rem;
`;
export const SPage = styled(Page)`
  padding: 0;
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: ${props => props.theme.palette.background.primary};
`;
