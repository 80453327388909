import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { sessionStorageGet, sessionStorageRemove, sessionStorageSet } from '../../utils/storage';
import {
  CecMessageSendingMethod,
  getMessageLimit,
  getTrackingLinkCount,
} from '../CreateCecMessage/helpers';
import { useAppSelector } from '../../hooks';
import { selectSmsLength } from '../ExternalContacts/externalContactsSlice';
import { count } from 'sms-length';

interface CreateCecMessageCtxState {
  contactListIds: Set<number>;
  sendMethods: CecMessageSendingMethod[];
  setContactListIds: (set: Set<number>) => void;
  setSendMethods: React.Dispatch<React.SetStateAction<number[]>>;
  addTrackingLink: boolean;
  setAddTrackingLink: React.Dispatch<React.SetStateAction<boolean>>;
  cecMessageText: string;
  setCecmessageText: (text: string) => void;
  onFinish: () => void;
  calculateMessageLimit: (text?: string) => number;
}

const CreateCecMessageCtxDefaultValue: CreateCecMessageCtxState = {
  contactListIds: new Set(),
  setContactListIds: () => {},
  sendMethods: [],
  setSendMethods: () => {},
  addTrackingLink: false,
  setAddTrackingLink: () => {},
  cecMessageText: '',
  setCecmessageText: () => {},
  onFinish: () => {},
  calculateMessageLimit: () => 0,
};

const CreateCecMessageCtx = createContext<CreateCecMessageCtxState>(
  CreateCecMessageCtxDefaultValue
);

export const useCreateCecMessageCtx = () => useContext(CreateCecMessageCtx);

export const CreateCecMessageCtxKey = 'CREATE_CEC_MESSAGE';

interface CreateCecMessageCtxProviderProps {
  children: React.ReactNode;
}
export const CreateCecMessageCtxProvider = (props: CreateCecMessageCtxProviderProps) => {
  const { children } = props;

  const [contactListIds, setContactListIds]: [Set<number>, (set: Set<number>) => void] = useState(
    new Set()
  );
  const [sendMethods, setSendMethods] = useState<CecMessageSendingMethod[]>([]);
  const [addTrackingLink, setAddTrackingLink] = useState<boolean>(false);
  const [cecMessageText, setCecmessageText] = useState<string>('');
  const smsLength = useAppSelector(selectSmsLength);

  // read and write from session storage
  // delete the props after complete the the send
  useEffect(() => {
    const retrived = sessionStorageGet(CreateCecMessageCtxKey);
    if (retrived) {
      // if there is an retrived let's parse it so we can have it ready ;)
      try {
        const {
          sendMethods: sendMethodsProp,
          contactListIds: contactListIdsProp,
          cecMessageText: cecMessageTextProp,
          addTrackingLink: addTrackingLinkProp,
        } = JSON.parse(retrived);
        setSendMethods(sendMethodsProp);
        setContactListIds(new Set(contactListIdsProp));
        setCecmessageText(cecMessageTextProp);
        setAddTrackingLink(addTrackingLinkProp);
      } catch {
        console.error('not valid JSON');
      }
    }
  }, []);
  useEffect(() => {
    const toSave = JSON.stringify({
      sendMethods,
      contactListIds: Array.from(contactListIds),
      cecMessageText,
      addTrackingLink,
    });
    sessionStorageSet(CreateCecMessageCtxKey, toSave);
  }, [sendMethods.length, contactListIds.size, cecMessageText, addTrackingLink]);

  const calculateMessageLimit = (text?: string) => {
    return getMessageLimit({
      methods: sendMethods,
      text: text ?? cecMessageText,
      withTrackingLinkofLenght: addTrackingLink ? getTrackingLinkCount(smsLength) : undefined,
    });
  };

  const setMessageText = (text: string) => {
    const limit = calculateMessageLimit(text);
    const textLength = count(text).length;
    if (textLength <= limit) {
      setCecmessageText(text);
    }
  };

  const onFinish = () => {
    sessionStorageRemove(CreateCecMessageCtxKey);
  };

  const value = useMemo(
    () => ({
      contactListIds,
      setContactListIds,
      sendMethods,
      setSendMethods,
      addTrackingLink,
      setAddTrackingLink,
      cecMessageText,
      setCecmessageText: setMessageText,
      onFinish,
      calculateMessageLimit,
    }),
    [contactListIds, sendMethods, addTrackingLink, cecMessageText]
  );
  return <CreateCecMessageCtx.Provider value={value}>{children}</CreateCecMessageCtx.Provider>;
};
