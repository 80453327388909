import React from 'react';
import { useTranslation } from 'react-i18next';
import { palette } from '../../../theme/colors';
import { SettingsSection } from './Settings';
import Loader from '../../Loader/Loader';
import { SLine, SLoaderContainer, SOption, Switch } from './SettingsItem.styles';

function SettingsItem(props: SettingsSection) {
  const { name, id, checked, callback, isLoading, visable } = props;
  const { t } = useTranslation();
  const nameTx = t(`${name}`);

  if (!visable) return <></>;

  return (
    <>
      {id !== 0 && (
        <SLine role="line">
          <rect width="400" height="100" fill={palette.queenBlue} />
        </SLine>
      )}
      <SOption role={`settingsItem-${nameTx}`}>
        <p role="title">{nameTx}</p>
        {isLoading && (
          <SLoaderContainer>
            <Loader size={34} />
          </SLoaderContainer>
        )}
        {!isLoading && (
          <Switch role="switchContainer">
            <input
              type="checkbox"
              value={name}
              checked={checked}
              onChange={() => {
                callback(checked);
              }}
              id={id.toString()}
            />
            <span />
          </Switch>
        )}
      </SOption>
    </>
  );
}

export default SettingsItem;
