import React, { useEffect, useState } from 'react';
import { ImOkDocumentSimple } from '../../containers/ImOkList/imOkSlice/types';
import _ from 'lodash';
import {
  CreatorName,
  IconImage,
  MessagesContainer,
  SimpleText,
  Subject,
  ToggleButton,
  TopRow,
} from './ImOk.styles';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';
import { translate } from '../../utils/translate';
import openText from '../../assets/imgs/general/open-text.svg';
import closeText from '../../assets/imgs/general/close-text.svg';
import HyperLinkText from '../HyperlinkText/HyperLinkText';

export interface ImOkDocumentProps {
  imOkDocument: ImOkDocumentSimple;
  onMessagedToggled?: (toggled: boolean) => void;
}

const ImOkMessage = React.forwardRef<HTMLDivElement, ImOkDocumentProps>((props, ref) => {
  const { imOkDocument, onMessagedToggled } = props;

  const { name, created } = imOkDocument;
  const [isOpen, setIsOpen] = useState(true);
  const maxMessagesLength = 52;
  const messageText = isOpen
    ? name
    : _.truncate(name, {
        length: maxMessagesLength,
      });

  useEffect(() => {
    if (onMessagedToggled) {
      onMessagedToggled(isOpen);
    }
  }, [isOpen]);
  return (
    <MessagesContainer ref={ref} id={`imOkMessage-${name}`}>
      <TopRow role="imOkMessageTopRow" marginBottom={'0.2rem'}>
        <CreatorName role="imOkMessageCreator">{imOkDocument.creatorname}</CreatorName>
        <Subject role="imOkMessageSubject">{imOkDocument?.subject || ''}</Subject>
      </TopRow>
      <TopRow role="imOkSecondRow" marginBottom={'0.4rem'}>
        <SimpleText role="imOkToText">{`${translate('imOk_to')}: ${
          imOkDocument?.groups.length ? imOkDocument?.groups[0]?.name : ''
        }`}</SimpleText>
        <SimpleText role="imOkDate">
          {getNumbersBasedDateTextByLanguage({ dateText: created, showOnlyTimeIfToday: true })}
        </SimpleText>
      </TopRow>
      <TopRow role="imOkMessage">
        <SimpleText role="imOkMessageText">
          {translate('imOk_message')}: <HyperLinkText text={messageText} />
        </SimpleText>

        {name.length > maxMessagesLength && (
          <ToggleButton role="imOkToggleButton" onClick={() => setIsOpen(!isOpen)}>
            <IconImage alt="" src={isOpen ? openText : closeText} />
          </ToggleButton>
        )}
      </TopRow>
    </MessagesContainer>
  );
});

export default ImOkMessage;
