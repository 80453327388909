import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const Backdrop = styled.div<{ $blocking?: boolean }>`
  z-index: ${props => (props.$blocking ? 1000 : 1)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${palette.navyBlue};
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: ${toRem(20)};
`;
