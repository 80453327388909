import styled, { css } from 'styled-components';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const recipientGroupsCss = css`
  padding: 1.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SPage = styled(Page)`
  ${recipientGroupsCss}
`;

export const SContainer = styled.div``;

export const SRecipientContainer = styled.div<{ isLastItem?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${props => (props.isLastItem ? palette.transparent : palette.tinyBorder)};
  padding: ${toRem(12)} 0;
  padding-bottom: ${props => props.isLastItem && toRem(30)};
  .title {
    font-family: 'Roboto-Regular';
    font-size: ${toRem(16)};
    line-height: ${toRem(20)};
  }
  .subtitle {
    font-size: ${toRem(12)};
    color: ${palette.ashesGray};
    font-weight: 400;
    margin-top: ${toRem(2)};
  }
`;

export const SProfilePictureContainer = styled.div`
  padding-right: ${toRem(12)};
`;

export const SPrebuiltPage = styled.div<{ padding?: string }>`
  padding: ${props => props.padding};
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
