import { batch } from 'react-redux';
import type { NavigateFunction } from 'react-router-dom';
import {
  resetCheckListSelections,
  setActiveChecklist,
  setChecklistItems,
  setChecklists,
  setChecklistSummary,
  setGroups,
  setIsCommentSending,
  setIsGroupsLoading,
  setIsLoading,
  setIsUsersLoading,
  setScrollToChecklistItemId,
  setUsers,
} from '.';
import {
  addComment,
  changeItemStatus,
  checklistContinue,
  createNewChecklist,
  deleteTemplate,
  editChecklist,
  endChecklist,
  getChecklist,
  getChecklistItems,
  getChecklists,
  getChecklistsSummary,
  renameChecklist,
  saveItems,
  shareChecklist,
  shareChecklistWithUsers,
  startChecklist,
  startChecklistGroupsAndUsers,
  StartChecklistGroupsAndUsersParams,
  unshareChecklist,
  unShareChecklistWithUsers,
} from '../../../apis/checklistsAPI';
import { GetChecklistProps, GetChecklistType } from '../../../apis/checklistsAPI/types';
import { getGroupById, getGroups } from '../../../apis/groupsAPI';
import { GetGroupsQuery } from '../../../apis/groupsAPI/types';
import { getAllUsers, getUserById, GetUserQuery } from '../../../apis/userAPI';
import { AppThunk } from '../../../store';
import { ChecklistTask } from './types';
import {
  initialChecklistPagingValues,
  defaultCheckListFiltersByStatus,
  mappedChecklistType,
} from '../Checklists.models';
import { Group } from '../../GroupsList/groupsSlice/types';
import { SelectListUser } from '../../CreateMessage/createMessageSlice/types';

export interface CreateChecklistParams {
  list: {
    sortIndex: number;
    name: string;
  }[];
  userIds: number[];
  groupIds: number[];
  onSuccess: () => void;
  checklistName: string;
}
export interface EditChecklistParams {
  checklistID: number;
  selectedGroupIds: number[];
  unselectedGroupsIds: number[];
  selectedUsersIds: number[];
  unSelectedUsersIds: number[];
  onSuccess: () => void;
}

export const fetchUsers =
  (query?: GetUserQuery, userIds?: number[], shouldNotLoadAllGroups = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsUsersLoading(true));
      let users: SelectListUser[] = [];
      if (userIds && shouldNotLoadAllGroups) {
        users = await Promise.all(userIds.map(id => getUserById(id)));
      } else {
        if (userIds?.length) {
          users = await Promise.all(userIds.map(id => getUserById(id)));
        }
        const usersByQuery = await getAllUsers(query);
        const filteredUsersByQuery = usersByQuery.filter(
          user => !users.find(u => u.id === user.id)
        );
        users = [...users, ...filteredUsersByQuery];
      }
      batch(() => {
        dispatch(setUsers(users));
        dispatch(setIsUsersLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);

      dispatch(setIsUsersLoading(false));
    }
  };

export const fetchAdditionalUsersByPage =
  (query?: GetUserQuery): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsUsersLoading(true));
      const currentUsers = getState().checklists.users || [];
      const additionalUsers = await getAllUsers(query);
      const filteredAdditionalUsers = [...additionalUsers].filter(
        user => !currentUsers.find(u => u.id === user.id)
      );
      const allUsers = [...currentUsers, ...filteredAdditionalUsers];
      batch(() => {
        dispatch(setUsers(allUsers));
        dispatch(setIsUsersLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      dispatch(setIsUsersLoading(false));
    }
  };

export const fetchGroups =
  (query?: GetGroupsQuery, groupIds?: number[], shouldNotLoadAllGroups = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsGroupsLoading(true));
      let groups: Group[] = [];
      if (groupIds && shouldNotLoadAllGroups) {
        groups = (await Promise.all(groupIds.map(id => getGroupById(id)))) as Group[];
      } else {
        groups = await getGroups(query);
        if (groupIds?.length) {
          groupIds.forEach(groupId => {
            const foundGroupIndex = groups.findIndex(g => g.id === groupId);
            const groupData = { ...groups[foundGroupIndex] };
            if (foundGroupIndex > -1) {
              groups.splice(foundGroupIndex, 1);
              groups.unshift(groupData);
            }
          });
        }
      }
      batch(() => {
        dispatch(setGroups(groups));
        dispatch(setIsGroupsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      dispatch(setIsGroupsLoading(false));
    }
  };

export const fetchAdditionalGroupsByPage =
  (query?: GetGroupsQuery): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsGroupsLoading(true));
      const currentGroups = getState().checklists.groups || [];
      const allGroups = [...currentGroups];
      const additionalGroups = await getGroups(query);
      additionalGroups.forEach(group => {
        const foundGroup = allGroups.find(u => u.id === group.id);
        if (!foundGroup) {
          allGroups.push(group);
        }
      });
      batch(() => {
        dispatch(setGroups(allGroups));
        dispatch(setIsGroupsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      dispatch(setIsGroupsLoading(false));
    }
  };

export const fetchChecklists =
  (
    props?: GetChecklistProps,
    isIntervalFetch = { is: false, resetSkip: () => {} },
    isPaging = false
  ): AppThunk =>
  async (dispatch, getState) => {
    const defaultProps = {
      ...initialChecklistPagingValues,
      type: [GetChecklistType.active],
    };
    const prevChecklists = getState().checklists.checklists;

    try {
      if (!isPaging && !isIntervalFetch.is) {
        dispatch(setIsLoading(true));
      }

      if (isIntervalFetch.is && prevChecklists) {
        const newChecklists = await getChecklists(
          props ? { ...props, skip: 0, limit: prevChecklists.length } : defaultProps
        );
        if (prevChecklists.length !== newChecklists.length) return;
        for (let index = 0; index < prevChecklists.length; index++) {
          if (prevChecklists[index].id !== newChecklists[index].id) {
            dispatch(setChecklists(newChecklists));
            isIntervalFetch.resetSkip();
            break;
          }
        }
        return;
      }

      const newChecklists = await getChecklists(props ?? defaultProps);

      batch(() => {
        dispatch(setChecklists(isPaging ? [...prevChecklists, ...newChecklists] : newChecklists));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      dispatch(setIsLoading(false));
    }
  };

export const saveChecklistItems =
  (id: number, items: { name: string; sortIndex: number }[], onSuccess: () => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      const res = await saveItems(id, items);
      if (res) {
        const activeTab = getState().checklists.activeTab;
        dispatch(
          fetchChecklists({
            ...initialChecklistPagingValues,
            ...defaultCheckListFiltersByStatus(activeTab),
            type: [mappedChecklistType[activeTab]],
          })
        );
        onSuccess();
      }
    } catch (error) {
      console.log('error log ', error);
    }
  };

export const createChecklist =
  (params: CreateChecklistParams): AppThunk =>
  async dispatch => {
    const { groupIds, list, onSuccess, userIds, checklistName } = params;
    try {
      const res = await createNewChecklist(checklistName, userIds, groupIds);
      if (res) {
        dispatch(saveChecklistItems(res.id, list, onSuccess));
      }
    } catch (error) {
      console.log('error log ', error);
    }
  };

export const changeChecklistItemStatus =
  (id: number, itemId: number, completed: boolean): AppThunk =>
  async (dispatch, getState) => {
    try {
      const res = await changeItemStatus(id, itemId, completed);
      if (res) {
        const newItems = [...getState().checklists.checklistItems];
        const foundIndex = newItems.findIndex(e => e.id === itemId);
        newItems[foundIndex] = res;

        dispatch(setChecklistItems(newItems));
      }
    } catch (error) {
      console.log('error log ', error);
    }
  };

export const fetchChecklistItems =
  (id: number, silentUpdate = false): AppThunk =>
  async dispatch => {
    if (!silentUpdate) dispatch(setIsLoading(true));
    try {
      const checklistItems = await getChecklistItems(id);
      if (checklistItems) {
        batch(() => {
          if (!silentUpdate) dispatch(setIsLoading(false));
          dispatch(setChecklistItems(checklistItems));
        });
      }
    } catch (error) {
      console.log('error log ', error);

      if (!silentUpdate) dispatch(setIsLoading(false));
    }
  };

export const sendComment =
  (id: number, itemID: number, model: { text: string | null }): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsCommentSending(true));
      dispatch(setScrollToChecklistItemId(itemID));
      const res = await addComment(id, itemID, model);
      if (res) {
        dispatch(fetchChecklistItems(id));
      }
      dispatch(setIsCommentSending(false));
    } catch (error) {
      console.log('error log ', error);
      dispatch(setIsCommentSending(false));
    }
  };

export const fetchChecklistSummary =
  (searchText: string | null, selectedFilters: GetChecklistProps): AppThunk =>
  async dispatch => {
    try {
      const summary = await getChecklistsSummary(searchText, selectedFilters);
      dispatch(setChecklistSummary(summary));
    } catch (error) {
      console.log('error fetchChecklistSummary ', error);
    }
  };

export const fetchMoreChecklists = (): AppThunk => async dispatch => {
  try {
    const checklists = await getChecklists({});
    dispatch(setChecklists(checklists));
  } catch (error) {
    console.log('error log ', error);
  }
};

export const fetchOneChecklist =
  (id: number, callBack?: () => void): AppThunk =>
  async dispatch => {
    try {
      const checklist = await getChecklist(id);
      dispatch(setActiveChecklist(checklist));

      callBack?.();
    } catch (error) {
      console.log('error log ', error);
    }
  };

export const renameAChecklist =
  (id: number, newName: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      await renameChecklist(id, newName);
      const activeTab = getState().checklists.activeTab;

      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(
          fetchChecklists({
            ...initialChecklistPagingValues,
            ...defaultCheckListFiltersByStatus(activeTab),
            type: [mappedChecklistType[activeTab]],
          })
        );
        dispatch(fetchOneChecklist(Number(id)));
      });
    } catch (error) {
      console.log('error log ', error);

      dispatch(setIsLoading(false));
    }
  };

export const fetchEndChecklist =
  (id: number, navigate: NavigateFunction): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      await endChecklist(id);
      const activeTab = getState().checklists.activeTab;

      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(
          fetchChecklists({
            ...initialChecklistPagingValues,
            ...defaultCheckListFiltersByStatus(activeTab),
            type: [mappedChecklistType[activeTab]],
          })
        );
        navigate(`/checklist/${id}`);
        dispatch(fetchOneChecklist(Number(id)));
      });
    } catch (error) {
      console.log('error log ', error);

      dispatch(setIsLoading(false));
    }
  };

export const fetchDeleteTemplate =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      await deleteTemplate(id);
      const activeTab = getState().checklists.activeTab;

      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(
          fetchChecklists({
            ...initialChecklistPagingValues,
            ...defaultCheckListFiltersByStatus(activeTab),
            type: [mappedChecklistType[activeTab]],
          })
        );
      });
    } catch (error) {
      console.log('error log', error);
      dispatch(setIsLoading(false));
    }
  };

export const fetchContinueChecklist =
  (id: number, onSuccess: () => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      await checklistContinue(id);
      const activeTab = getState().checklists.activeTab;

      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(
          fetchChecklists({
            ...initialChecklistPagingValues,
            ...defaultCheckListFiltersByStatus(activeTab),
            type: [mappedChecklistType[activeTab]],
          })
        );
      });
      onSuccess();
    } catch (error) {
      console.log('error log', error);
      dispatch(setIsLoading(false));
    }
  };

export const startChecklistAction =
  (id: number, params: StartChecklistGroupsAndUsersParams, navigate: NavigateFunction): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      const res =
        params.userIds?.length !== 0
          ? await startChecklistGroupsAndUsers(id, params)
          : await startChecklist(id, params.groupIds, params.newName);
      if (res) {
        const activeTab = getState().checklists.activeTab;
        dispatch(
          fetchChecklists({
            ...initialChecklistPagingValues,
            ...defaultCheckListFiltersByStatus(activeTab),
            type: [mappedChecklistType[activeTab]],
          })
        );
        navigate(`/checklist/${res.id}`);
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      console.log('error log', error);
      dispatch(setIsLoading(false));
    }
  };

export const shareChecklistWithGroups =
  (id: number, groupIDs: number[], onSuccess: () => void): AppThunk =>
  dispatch => {
    dispatch(setIsLoading(true));
    const promises: unknown[] = [];
    groupIDs.forEach(groupID => {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            const res = await shareChecklist(id, groupID);
            resolve(res);
          } catch (error) {
            console.log(`error sharing with group: ${groupID}. Error: ${error}`);
            reject();
          }
        })
      );
    });
    Promise.allSettled(promises)
      .then(() => {
        dispatch(setIsLoading(false));
        onSuccess();
      })
      .catch(err => console.log(err));
  };

export const unshareChecklistWithGroups =
  (id: number, groupIDs: number[], onSuccess: () => void): AppThunk =>
  dispatch => {
    dispatch(setIsLoading(true));
    const promises: unknown[] = [];
    groupIDs.forEach(groupID => {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            const res = await unshareChecklist(id, groupID);
            resolve(res);
          } catch (error) {
            console.log(`error sharing with group: ${groupID}. Error: ${error}`);
            reject();
          }
        })
      );
    });
    Promise.allSettled(promises)
      .then(() => {
        onSuccess();
        dispatch(setIsLoading(false));
      })
      .catch(err => console.log(err));
  };

export const editChecklistItems =
  (checklistID: number, items: ChecklistTask[], onSuccess: () => void): AppThunk =>
  async dispatch => {
    try {
      await editChecklist(checklistID, items);
      onSuccess();
    } catch (error) {
      console.log('error log', error);
      dispatch(setIsLoading(false));
    }
    onSuccess();
  };

export const editChecklistGroups =
  (params: EditChecklistParams): AppThunk =>
  async (dispatch, getState) => {
    const {
      checklistID,
      onSuccess,
      selectedGroupIds,
      unselectedGroupsIds,
      selectedUsersIds,
      unSelectedUsersIds,
    } = params;
    let resolved = 0;
    dispatch(setIsLoading(true));

    const handleResolved = () => {
      resolved++;
      if (resolved === 2) {
        const activeTab = getState().checklists.activeTab;

        batch(() => {
          dispatch(
            fetchChecklists({
              ...initialChecklistPagingValues,
              ...defaultCheckListFiltersByStatus(activeTab),
              type: [mappedChecklistType[activeTab]],
            })
          );
          dispatch(fetchGroups({ menuitem: 'checklists' }));
          dispatch(resetCheckListSelections());
        });
        dispatch(setIsLoading(false));
        onSuccess();
      }
    };
    await Promise.all([
      selectedUsersIds.length && shareChecklistWithUsers(checklistID, selectedUsersIds),
      unSelectedUsersIds.length && unShareChecklistWithUsers(checklistID, unSelectedUsersIds),
    ]);
    dispatch(unshareChecklistWithGroups(checklistID, unselectedGroupsIds, handleResolved));
    dispatch(shareChecklistWithGroups(checklistID, selectedGroupIds, handleResolved));
  };

export const handleChecklistDataNotification =
  (checklistId: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      const checklist = getState().checklists.activeChecklist;
      if (checklist?.id === checklistId) {
        batch(() => {
          dispatch(fetchOneChecklist(Number(checklistId)));
          dispatch(fetchChecklistItems(Number(checklistId), true));
        });
      }
    } catch (error) {
      console.log('error log', error);
    }
  };
