import styled from 'styled-components';
import { palette } from '../../../theme/colors';

export const SFlexDiv = styled.div`
  flex: 1;
`;

export const SNotAvailableContainer = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${palette.queenBlue};
`;

export const STitle = styled.p`
  font-family: 'Roboto-Bold';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${palette.white};
  margin-bottom: 0.5rem;
  &.general {
    margin-bottom: 1rem;
  }
`;
