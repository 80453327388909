import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { CheckAppAvailableState } from './types';

const initialState: CheckAppAvailableState = {
  isOnline: true,
  isLoading: false,
  isUpdateRecommended: false,
  isUpdateRequired: false,
};

export const checkAppAvailableStateSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsAppUpdateRecommended: (state, action: PayloadAction<boolean>) => {
      state.isUpdateRecommended = action.payload;
    },
    setIsAppUpdateRequired: (state, action: PayloadAction<boolean>) => {
      state.isUpdateRequired = action.payload;
    },
  },
});

// export store actions
export const { setIsLoading, setIsOnline, setIsAppUpdateRecommended, setIsAppUpdateRequired } =
  checkAppAvailableStateSlice.actions;

export const selectIsOnLine = (state: RootState) => state.checkAppAvailableState.isOnline;
export const selectIsLoading = (state: RootState) => state.checkAppAvailableState.isLoading;
export const selectIsAppUpdateRequired = (state: RootState) =>
  state.checkAppAvailableState.isUpdateRequired;
export const selectIsAppUpdateRecommended = (state: RootState) =>
  state.checkAppAvailableState.isUpdateRecommended;

// export the reducer
export default checkAppAvailableStateSlice.reducer;
