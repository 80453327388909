import { createContext, useContext, useMemo, useState } from 'react';
import { sessionStorageRemove } from '../../utils/storage';

interface StartOnCallAlertMessageCtxState {
  groupIds: number | undefined;
  userIds: Set<number>;

  setGroupIds: React.Dispatch<React.SetStateAction<number | undefined>>;
  setUserIds: (set: Set<number>) => void;

  onCallAlertMessageText: string;
  setOnCallAlertMessageText: (text: string) => void;

  subject: string;
  setSubject: React.Dispatch<React.SetStateAction<string>>;

  onFinish: () => void;
}

const StartOnCallALertMessageCtxDefaultValue: StartOnCallAlertMessageCtxState = {
  groupIds: undefined,
  userIds: new Set(),

  setGroupIds: () => {},
  setUserIds: () => {},

  onCallAlertMessageText: '',
  setOnCallAlertMessageText: () => {},

  subject: '',
  setSubject: () => {},

  onFinish: () => {},
};

const StartOnCallALertMessageCtx = createContext<StartOnCallAlertMessageCtxState>(
  StartOnCallALertMessageCtxDefaultValue
);

export const TEXT_LENGTH_LIMIT = 300;

export const useStartOnCallAlertMessageCtx = () => useContext(StartOnCallALertMessageCtx);

const StartOnCallAlertMessageCtxKey = 'START_OnCallAlert_MESSAGE';

interface StartOnCallAlertMessageCtxProviderProps {
  children: React.ReactNode;
}
export const StartOnCallAlertMessageCtxProvider = (
  props: StartOnCallAlertMessageCtxProviderProps
) => {
  const { children } = props;

  const [groupIds, setGroupIds] = useState<number>();

  const [userIds, setUserIds]: [Set<number>, (set: Set<number>) => void] = useState(new Set());

  const [onCallAlertMessageText, setOnCallAlertMessageText] = useState<string>('');

  const [subject, setSubject] = useState<string>('');

  const setMessageText = (text: string) => {
    if (text.length <= TEXT_LENGTH_LIMIT) {
      setOnCallAlertMessageText(text);
    }
  };

  const onFinish = () => {
    sessionStorageRemove(StartOnCallAlertMessageCtxKey);
  };

  const value = useMemo(
    () => ({
      groupIds,
      userIds,
      subject,

      setGroupIds,
      setUserIds,
      setSubject,

      onCallAlertMessageText,
      setOnCallAlertMessageText: setMessageText,

      onFinish,
    }),
    [groupIds, subject, onCallAlertMessageText, userIds]
  );
  return (
    <StartOnCallALertMessageCtx.Provider value={value}>
      {children}
    </StartOnCallALertMessageCtx.Provider>
  );
};
