import { useEffect, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  isChatsLoading,
  selectChatReply,
  selectCurrentChat,
  selectCurrentReadReceipt,
  selectError,
  selectMessagesRecipients,
  setCurrentReplyReadReceipt,
  setMessagesRecipients,
} from '../ChatsList/chatListSlice';
import { SPage, SUserList, SUsersContainer } from './ReadReceipts.styles';
import { generateReadDateElement, generateReadUserCountsForTranslation } from './helpers';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader/Loader';
import { ReadReceiptUserThumb } from '../../components/ReadReceiptUserThumb/ReadReceiptUserThumb';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { getImageLink } from '../../utils/formatImageLink';
import { fetchReadReceipt } from '../ChatsList/chatListSlice/actionCreators';
import { selectUser } from '../Login/LoginSlice';

export const ReadReceipts = () => {
  const { id: messageId, replyId } = useParams();

  const navigate = useNavigate();
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const user = useAppSelector(selectUser);
  const readReceipt = useAppSelector(selectCurrentReadReceipt);
  const messageReceipt = useAppSelector(selectMessagesRecipients);
  const message = useAppSelector(selectCurrentChat);
  const loading = useAppSelector(isChatsLoading);
  const error = useAppSelector(selectError);
  const reply = useAppSelector(selectChatReply(+replyId!));

  // we should expect a message reply to be since the selected id (replyId) is not the same as message id
  const isReplyExpected = replyId !== messageId;
  const noReplyFound = !reply && isReplyExpected;

  useLayoutEffect(() => {
    dispatch(
      fetchReadReceipt({
        isReplyExpected,
        messageId: +messageId!,
        replyId: +replyId!,
      })
    );
    return () => {
      dispatch(setCurrentReplyReadReceipt(undefined));
      dispatch(setMessagesRecipients([]));
    };
  }, []);

  useEffect(() => {
    if (message === null && error === 'Message is deleted') {
      confirm({
        title: 'message_not_found_title',
        description: 'message_not_found_desc',
        onSubmit: () => navigate('/chat'),
        confirmText: 'ok',
        blockOutsideClick: true,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (noReplyFound && message?.replies && message?.replies.length !== 0) {
      confirm({
        title: 'message_reply_not_found_title',
        description: 'message_not_found_desc',
        onSubmit: () => navigate('/chat'),
        confirmText: 'ok',
        blockOutsideClick: true,
      });
    }
  }, [reply]);

  if (!message || message.id !== Number(messageId) || loading || !user || noReplyFound) {
    return (
      <SPage>
        <Loader />
      </SPage>
    );
  }

  return (
    <SPage>
      <SUsersContainer role="usersCountContainer">
        <p className="usersCount" role="usersCount">
          {t(
            'message_users_seen_count',
            generateReadUserCountsForTranslation(isReplyExpected, readReceipt, messageReceipt)
          )}
        </p>
        <SUserList role="usersList">
          {readReceipt?.reads.map((userItem, index) => (
            <ReadReceiptUserThumb
              key={`${index}-${userItem.userId}`}
              title={userItem.name}
              subTitle={userItem.title}
              showAvailability={userItem.available === false}
              dateElements={generateReadDateElement(userItem.readTime)}
              imageSource={
                userItem.photoFileName &&
                getImageLink({
                  imageName: userItem.photoFileName,
                })
              }
            />
          ))}
          {readReceipt?.notReads.map((userItem, index) => (
            <ReadReceiptUserThumb
              key={`${index}-${userItem.userID}`}
              title={userItem.displayName}
              showAvailability={userItem.available === false}
              imageSource={
                userItem.photoFileName &&
                getImageLink({
                  imageName: userItem.photoFileName,
                })
              }
            />
          ))}
          {!isReplyExpected &&
            messageReceipt &&
            messageReceipt.map((userItem, index) => (
              <ReadReceiptUserThumb
                key={`${index}-${userItem.userID}`}
                title={userItem.displayName}
                imageSource={
                  userItem.photoFileName &&
                  getImageLink({
                    imageName: userItem.photoFileName,
                  })
                }
                showAvailability={userItem.available === false}
                subTitle={userItem.title}
                dateElements={
                  userItem.read && userItem.firstRead
                    ? generateReadDateElement(userItem.firstRead)
                    : undefined
                }
              />
            ))}
        </SUserList>
      </SUsersContainer>
    </SPage>
  );
};
