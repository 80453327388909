import isElectron from 'is-electron';
import { useEffect } from 'react';

export function useOnlineStatus(onAppOnline: () => void) {
  useEffect(() => {
    if (!isElectron()) {
      // Only relevant for the Desktop app
      return;
    }

    const handleOnline = () => onAppOnline();
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, [onAppOnline]);
}
