/* eslint-disable @typescript-eslint/naming-convention */

import {
  Chat,
  FooterData,
  MessageInList,
  MessageListFilterGroup,
  MessageReactions,
  MessageReactionsItem,
  MessageReactionTypes,
  MessageRead,
  MessagesRecipients,
  ReadReceipt,
  Reply,
  TotalUnread,
} from '../containers/ChatsList/chatListSlice/types';
import { CreateMessageModel, MessageReplyModel } from '../containers/Chat/models';

import { ApiCore } from './utils/core';
import { ReplyToMessageType } from '../utils/enums';
import { getAntiForgeryToken } from './authApi/authAPI';
import { indexInterface } from './groupsAPI/types';
import { objectToQueryParams } from '../utils/format';
import { removeNullAndUndefinedPropertiesFromObject } from '../utils/helperFunctions';

const url = 'messages';

const chatAPI = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  remove: false,
  singleExtra: true,
  url,
});

export interface GetMessageReactionsProps {
  messageId: number;
  skip?: number;
  take?: number;
  reactionTypeId?: string;
}

export interface GetMessageReplyReactionsProps extends GetMessageReactionsProps {
  replyId: number;
}

export interface GetChatsProps extends indexInterface {
  types?: number[];
  excludTypes?: number[];

  skip?: number;
  take?: number;
  treshold?: string;
  direction?: 0 | 1;
  includeReplies?: boolean;
  hasAttachments?: 0 | 1 | 2;
  // it isn't needed since the there is no option to change the sorting
  sort?: 'LastReplySent' | 'Sent';
  search?: string;
  // filter by group ids
  groups?: number[];
  maxReplyId?: number;
  minReplyId?: number;
}

export interface GetActiveAlarmsProps {
  groupIds: number[];
  minSentDate?: string;
  skip?: number;
  take?: number;
}

export const getChats = (props: GetChatsProps) => {
  return chatAPI.performExtra<MessageInList[]>({
    method: 'GET',
    extraResource: `?${objectToQueryParams(props)}`,
    version: 'v2',
  });
};

export const getGroupsOfMessageList = () => {
  return chatAPI.performExtra<MessageListFilterGroup[]>({
    method: 'GET',
    extraResource: `groups`,
  });
};

export const getSingleChat = (id: string, props?: GetChatsProps) => {
  return chatAPI.performExtra<Chat>({
    method: 'GET',
    extraResource: props ? `${id}?${objectToQueryParams(props)}` : id,
    version: 'v2',
  });
};

export const getChatReplies = (id: string | number, props?: GetChatsProps) => {
  return chatAPI.performExtra<Reply[]>({
    method: 'GET',
    extraResource: props ? `${id}/replies?${objectToQueryParams(props)}` : `${id}/replies`,
  });
};

export const messageRead = async (id: number, replyIds: number[]) => {
  const csrfToken = await getAntiForgeryToken();

  return chatAPI.performExtra<MessageRead>({
    method: 'POST',
    extraResource: `${id}/read`,
    model: {
      read: true,
      replyIds,
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const getTotalUnread = () => {
  return chatAPI.performExtra<TotalUnread>({
    method: 'GET',
    extraResource: 'TotalUnread',
  });
};

export const getFooterData = () => {
  return chatAPI.performExtra<FooterData>({
    method: 'GET',
    extraResource: 'FooterData',
  });
};

export const archiveMessage = async (id: number) => {
  const csrfToken = await getAntiForgeryToken();

  return chatAPI.performExtra<{ ok?: boolean }>({
    method: 'POST',
    extraResource: `${id}/archive`,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const addGroupToMessage = async (
  messageId: number,
  groupId: number,
  recipientIDs: number[]
) => {
  const csrfToken = await getAntiForgeryToken();

  return chatAPI.performExtra<{ ok?: boolean }>({
    method: 'POST',
    extraResource: `${messageId}/addgroup/${groupId}`,
    model: { recipientIDs: recipientIDs },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const addRecipientsToMessage = async (messageId: number, recipientIDs: number[]) => {
  const csrfToken = await getAntiForgeryToken();

  return chatAPI.performExtra<{ ok?: boolean }>({
    method: 'POST',
    extraResource: `${messageId}/addRecipients`,
    model: { recipientIDs: recipientIDs },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const recallAlert = async (chatID: number) => {
  const csrfToken = await getAntiForgeryToken();

  return chatAPI.performExtra<{ ok?: boolean }>({
    method: 'POST',
    extraResource: `${chatID}/recall`,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const deleteChat = async (chatID: number) => {
  const csrfToken = await getAntiForgeryToken();

  return chatAPI.performExtra<{ ok?: boolean }>({
    method: 'DELETE',
    extraResource: `${chatID}`,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const forwardMessage = async (messageID: number, groups: number[], users: number[]) => {
  const csrfToken = await getAntiForgeryToken();
  return chatAPI.performExtra<Chat>({
    method: 'POST',
    extraResource: `${messageID}/forward`,
    headers: { 'X-XSRF-Token': csrfToken },
    model: {
      groups,
      users,
    },
  });
};

export const getActiveAlarms = async (props: GetActiveAlarmsProps) => {
  return chatAPI.performExtra<any>({
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    extraResource: `activeAlarms?${objectToQueryParams(props)}`,
  });
};

export const createMessage = async (createMessageModel: CreateMessageModel) => {
  const {
    senderId,
    text,
    photoFileNames,
    documentFileNames,
    audioFileNames,
    groupIds,
    recipientIds,
    emergencyTypeId,
    locationId,
    type,
    subOrganisationIDForEmergencyMessage,
    subject,
    replyType,
    subType,
  } = createMessageModel;
  const csrfToken = await getAntiForgeryToken();
  return chatAPI.performExtra<Chat>({
    method: 'POST',
    extraResource: ``,
    headers: { 'X-XSRF-Token': csrfToken },
    model: {
      senderId,
      text,
      photoFileNames,
      documentFileNames,
      audioFileNames,
      groupIds,
      recipientIds,
      emergencyTypeId,
      locationId,
      type,
      subOrganisationIDForEmergencyMessage,
      subject,
      replyType,
      subType,
    },
  });
};

export const deleteReply = async (messageID: number, replyID: number) => {
  const csrfToken = await getAntiForgeryToken();

  return chatAPI.performExtra<{ ok?: boolean }>({
    method: 'DELETE',
    extraResource: `${messageID}/replies/${replyID}`,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const editMessage = async ({
  id,
  text,
  subject,
  isReply,
}: {
  id: number;
  text?: string;
  subject?: string;
  isReply?: boolean;
}) => {
  const csrfToken = await getAntiForgeryToken();
  const updateModel = removeNullAndUndefinedPropertiesFromObject({ text, subject });
  if (!isReply) {
    return chatAPI.performExtra<{ ok?: boolean }>({
      method: 'POST',
      extraResource: `${id}`,
      model: updateModel,
      headers: { 'X-XSRF-Token': csrfToken },
    });
  } else {
    return chatAPI.performExtra<{ ok?: boolean }>({
      method: 'POST',
      extraResource: `replies/${id}/edit`,
      model: updateModel,
      headers: { 'X-XSRF-Token': csrfToken },
    });
  }
};

export const replyToMessage = async (messageReply: MessageReplyModel) => {
  const {
    messageId,
    text,
    photoFileNames,
    documentFileNames,
    audioFileNames,
    locationId,
    replyId,
    replyToInitialMessage,
  } = messageReply;
  const csrfToken = await getAntiForgeryToken();

  return chatAPI.performExtra<{ ok?: boolean }>({
    method: 'POST',
    extraResource: `${messageId}/reply`,
    model: {
      text,
      photoFileNames,
      documentFileNames,
      attachments: audioFileNames,
      locationId,
      replyId,
      replyToInitialMessage,
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const getMessagesRecipients = (messageID: number) => {
  return chatAPI.performExtra<MessagesRecipients[]>({
    method: 'GET',
    extraResource: `${messageID}/recipients`,
  });
};

export interface ChangeReplyTypeParams {
  id: number;
  model: {
    messageType?: number;
    replyType?: ReplyToMessageType;
  };
}

export const _changeReplyType = async (params: ChangeReplyTypeParams) => {
  const { id, model } = params;
  const csrfToken = await getAntiForgeryToken();
  return chatAPI.performExtra<{ ok?: boolean }>({
    method: 'POST',
    extraResource: `${id}/ChangeType`,
    model,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export interface MessagesReplyOperationProps {
  messageId: number;
  replyId: number;
}

export const getReplyReadReceipt = (props: MessagesReplyOperationProps) => {
  const { messageId, replyId } = props;
  return chatAPI.performExtra<ReadReceipt>({
    method: 'GET',
    id: messageId,
    extraResource: `reply/${replyId}`,
  });
};

export const getMessageReactionTypes = () => {
  return chatAPI.performExtra<MessageReactionTypes>({
    method: 'GET',
    extraResource: `reaction-types`,
  });
};

export const getMessageReactions = (props: GetMessageReactionsProps) => {
  const { messageId, reactionTypeId, skip = 0, take = 100 } = props;
  const query = { reactionTypeId, skip, take };
  return chatAPI.performExtra<MessageReactions>({
    method: 'GET',
    extraResource: `${messageId}/reactions?${objectToQueryParams(query)}`,
  });
};

export const getMessageReplyReactions = (props: GetMessageReplyReactionsProps) => {
  const { messageId, reactionTypeId, replyId, skip = 0, take = 100 } = props;
  const query = { reactionTypeId, skip, take };
  return chatAPI.performExtra<MessageReactions>({
    method: 'GET',
    extraResource: `${messageId}/replies/${replyId}/reactions?${objectToQueryParams(query)}`,
  });
};

export const addMessageReaction = async (messageId: number, reactionTypeId: string) => {
  const csrfToken = await getAntiForgeryToken();
  return chatAPI.performExtra<MessageReactionsItem>({
    method: 'POST',
    extraResource: `${messageId}/reactions`,
    model: {
      reactionTypeId,
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const addMessageReplyReaction = async (
  messageId: number,
  replyId: number,
  reactionTypeId: string
) => {
  const csrfToken = await getAntiForgeryToken();
  return chatAPI.performExtra<MessageReactionsItem>({
    method: 'POST',
    extraResource: `${messageId}/replies/${replyId}/reactions`,
    model: {
      reactionTypeId,
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const editMessageReaction = async (
  messageId: number,
  reactionId: string,
  reactionTypeId: string
) => {
  const csrfToken = await getAntiForgeryToken();
  return chatAPI.performExtra<MessageReactionsItem>({
    method: 'PUT',
    extraResource: `${messageId}/reactions/${reactionId}`,
    model: {
      reactionTypeId,
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const editMessageReplyReaction = async (
  messageId: number,
  replyId: number,
  reactionId: string,
  reactionTypeId: string
) => {
  const csrfToken = await getAntiForgeryToken();
  return chatAPI.performExtra<MessageReactionsItem>({
    method: 'PUT',
    extraResource: `${messageId}/replies/${replyId}/reactions/${reactionId}`,
    model: {
      reactionTypeId,
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const deleteMessageReaction = async (messageId: number, reactionId: string) => {
  const csrfToken = await getAntiForgeryToken();
  return chatAPI.performExtra<any>({
    method: 'DELETE',
    extraResource: `${messageId}/reactions/${reactionId}`,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const deleteMessageReplyReaction = async (
  messageId: number,
  replyId: number,
  reactionId: string
) => {
  const csrfToken = await getAntiForgeryToken();
  return chatAPI.performExtra<any>({
    method: 'DELETE',
    extraResource: `${messageId}/replies/${replyId}/reactions/${reactionId}`,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};
