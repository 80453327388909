import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { removeSelectedOrgAction, sendBroadcastMsg } from './broadcastSlice/actionCreators';
import { getSelectedOrgs } from './broadcastSlice';
import useForm from '../../utils/customHooks/useForm';
import Pencil from '../../assets/imgs/general/pencil-icon.svg';
import { translate } from '../../utils/translate';
import ChatBox from '../../components/Chat/ChatBox';
import { saveDocumentToServer } from '../../apis/mediaAPI';
import { CreateMessageModel } from '../Chat/models';
import Loader from '../../components/Loader/Loader';
import { createMessageIsLoading, setIsLoading } from '../CreateMessage/createMessageSlice';
import {
  SPage,
  BroadcastView,
  SubjectContainer,
  SubjectLabel,
  SubjectInput,
  SubjectIcon,
} from './BroadcastNew.styles';
import { RecipientsSummaryList } from '../../components/RecipientsSummaryList/RecipientsSummaryList';
import { RecipientType } from '../RecipientGroups/RecipientGroups';

export interface BroadcastMsgForm {
  subject: string;
  message: string;
}

const BroadcastNew = (props: {
  setShowBottomTabs: React.Dispatch<React.SetStateAction<boolean>>;
  removeSelectedOrgAction: any;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedOrgs = useAppSelector(getSelectedOrgs);
  const isLoading = useAppSelector(createMessageIsLoading);
  const [tabBar, setTabBar] = useState(true);

  const subjectRef = useRef<HTMLInputElement>(null);

  const { inputs, handleChange, handleBlur } = useForm<BroadcastMsgForm>({
    initial: {
      subject: '',
      message: '',
    },
    validateSchema: {},
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleChange(e as React.ChangeEvent<HTMLInputElement>);
  };

  useEffect(() => {
    dispatch(setIsLoading(false));
    if (selectedOrgs.length === 0) {
      navigate('/broadcast');
    }
  }, [selectedOrgs]);

  const onRemoveOrg = (id: number) => {
    props.removeSelectedOrgAction(id);
  };

  const sendMessage = async (
    text: string | undefined,
    imageFileNames?: string[],
    documentFileNames?: string[],
    audioFileNames = [],
    locationId?: number
  ) => {
    const message: CreateMessageModel = {
      subject: inputs?.subject === '' ? null : inputs?.subject,
      documentFileNames: documentFileNames,
      audioFileNames: audioFileNames,
      photoFileNames: imageFileNames,
      text: text,
      locationId: locationId,
      subOrganizationID: _.head(selectedOrgs)?.id,
      organizationIds: selectedOrgs.map(e => e.id),
      Type: 0,
    };
    dispatch(sendBroadcastMsg(message, navigate));
  };

  const onPhotosSend = async (imagesList: string[], messageText: string) => {
    if (imagesList.length > 0) {
      const text = messageText && messageText.length > 0 ? messageText : undefined;
      sendMessage(text, imagesList);
    }
  };

  const onDocumentsSend = async (documentsList: File[]) => {
    const documentsFilesNamesArray = [];
    let document: File;
    for await (document of documentsList) {
      const formData = new FormData();
      formData.append('document', document);
      const result = await saveDocumentToServer(formData);
      if (result) {
        documentsFilesNamesArray.push(result);
      }
    }
    if (documentsFilesNamesArray.length > 0) {
      sendMessage(undefined, [], documentsFilesNamesArray);
    }
  };

  const highlightText = () => {
    if (subjectRef.current) subjectRef.current.focus();
  };

  if (isLoading) return <Loader />;

  return (
    <SPage>
      <BroadcastView role="broadcastCreateContainer">
        <RecipientsSummaryList
          data={selectedOrgs}
          handleAddClick={() => navigate('/broadcast')}
          handleRemoveClick={onRemoveOrg}
          recipientGroupsRoute={'/broadcast/recipient-accounts'}
          recipientsType={RecipientType.Accounts}
        />

        <SubjectContainer role="subjectContainer">
          <SubjectLabel role="subjectLabel">{translate('messages_subject_add')}</SubjectLabel>

          <SubjectInput
            role="subjectInput"
            type="text"
            name="subject"
            value={inputs.subject}
            placeholder={translate('messages_subject_add_placeholder') as string}
            onChange={onChange}
            onBlur={handleBlur}
            ref={subjectRef}
          />

          <SubjectIcon onClick={highlightText} role="subjectIcon">
            <img role="icon" src={Pencil} alt="addGroup" />
          </SubjectIcon>
        </SubjectContainer>
      </BroadcastView>

      <ChatBox
        hideLocationOption
        tabBar={tabBar}
        setTabBar={setTabBar}
        onTextSend={sendMessage}
        onPhotosSend={onPhotosSend}
        onDocumentsSend={onDocumentsSend}
        onLocationSend={(locationId, text) => sendMessage(text, [], [], [], locationId)}
      />
    </SPage>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeSelectedOrgAction: (id: number) => dispatch(removeSelectedOrgAction(id)),
  };
};

export default connect(null, mapDispatchToProps)(BroadcastNew);
