import { RecipientListItem } from './RecipientGroups';

export const getSortedAccounts = (accounts: RecipientListItem[]) => {
  const sortedAccounts = [...accounts].sort((a, b) => {
    // Sort by parentId: parent accounts (no parentId) come first
    if (!a.parentId && b.parentId) return -1;
    if (a.parentId && !b.parentId) return 1;

    return a.name.localeCompare(b.name);
  });

  return sortedAccounts;
};
