import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const SAddButton = styled.button`
  border: 0;
  height: ${toRem(32)};
  width: ${toRem(32)};
  aspect-ratio: 1;
  border-radius: 999rem;

  margin-right: ${toRem(6)};

  cursor: pointer;

  align-self: flex-start;

  background-color: ${palette.honeyYellow};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
