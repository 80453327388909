import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  CreatorName,
  IconImage,
  MessagesContainer,
  SimpleText,
  Subject,
  ToggleButton,
  TopRow,
} from './OnCallAlert.styles';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';
import { translate } from '../../utils/translate';
import openText from '../../assets/imgs/general/open-text.svg';
import closeText from '../../assets/imgs/general/close-text.svg';
import { OnCallAlertDocumentSimple } from '../../containers/OnCallAlertList/onCallAlertSlice/types';
import HyperLinkText from '../HyperlinkText/HyperLinkText';

export interface OnCallAlertMessageProps {
  onCallAlertDocument: OnCallAlertDocumentSimple;
  onMessagedToggled?: (toggled: boolean) => void;
}

const OnCallAlertMessage = React.forwardRef<HTMLDivElement, OnCallAlertMessageProps>(
  (props, ref) => {
    const { onCallAlertDocument, onMessagedToggled } = props;
    const { senderName, text, subject, groupName, started } = onCallAlertDocument;
    const [isOpen, setIsOpen] = useState(true);
    const maxMessagesLength = 52;
    const messageText = !text
      ? ''
      : isOpen
      ? text
      : _.truncate(text, { length: maxMessagesLength });

    useEffect(() => {
      if (onMessagedToggled) {
        onMessagedToggled(isOpen);
      }
    }, [isOpen]);

    return (
      <MessagesContainer ref={ref} role="messageContainer">
        <TopRow marginBottom={'0.2rem'} role="topRow">
          <CreatorName role="senderName">{senderName}</CreatorName>
          <Subject role="subject">{subject || ''}</Subject>
        </TopRow>
        <TopRow marginBottom={'0.4rem'} role="secondRow">
          <SimpleText role="sendTo">{`${translate('imOk_to')}: ${groupName}`}</SimpleText>
          <SimpleText role="dateText">
            {getNumbersBasedDateTextByLanguage({ dateText: started, showOnlyTimeIfToday: true })}
          </SimpleText>
        </TopRow>

        <TopRow role="messageRow">
          <SimpleText role="messageText">
            {`${translate('imOk_message')}: `}
            <HyperLinkText text={messageText} />
          </SimpleText>
          {text && text.length > maxMessagesLength && (
            <ToggleButton role="showMoreButton" onClick={() => setIsOpen(!isOpen)}>
              <IconImage role="showMoreIcon" alt="" src={isOpen ? openText : closeText} />
            </ToggleButton>
          )}
        </TopRow>
      </MessagesContainer>
    );
  }
);

export default OnCallAlertMessage;
