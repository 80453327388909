import * as React from 'react';
import SendIcon from '../../../assets/imgs/cec/cec-send-icon.svg';
import { translate } from '../../../utils/translate';
import {
  SChatInputContainer,
  SContentContainer,
  STextContainer,
  STextInput,
  STextInputWrapper,
  STextCounter,
  SIcon,
  SFakeFooter,
} from './CecChatInput.styles';

export interface ICecChatInputProps {
  onMessageSend: (text: string) => void;
  text: string;
  setText: (text: string) => void;

  limit?: number;
  textLength?: number;
}

export const CecChatInput = (props: ICecChatInputProps) => {
  const { onMessageSend, text, setText, limit, textLength } = props;

  React.useEffect(() => {
    if (textLength && limit && textLength > limit) {
      setText(text?.slice(0, limit));
    }
  }, [limit]);

  const notValid = limit && textLength ? textLength === 0 || textLength > limit : text.length === 0;

  const textInputRef = React.useRef<HTMLTextAreaElement>(null);

  const resetTextInputResize = () => {
    if (
      textInputRef.current !== undefined &&
      textInputRef.current !== null &&
      textInputRef.current.style !== undefined &&
      textInputRef.current.style.height !== undefined
    ) {
      textInputRef.current.style.height = '2.5rem';
    }
  };

  const handleSubmit = () => {
    resetTextInputResize();
    onMessageSend(text);
  };

  const limitedSize = 186;

  const handleTextInputResize = () => {
    if (
      textInputRef.current !== undefined &&
      textInputRef.current !== null &&
      textInputRef.current.style !== undefined &&
      textInputRef.current.style.height !== undefined
    ) {
      textInputRef.current.style.height = '2.5rem';
      if (textInputRef.current.scrollHeight >= limitedSize) {
        textInputRef.current.style.height = `${limitedSize}px`;
        textInputRef.current.style.borderRadius = `${20}px`;
      } else if (textInputRef.current.scrollHeight !== undefined) {
        textInputRef.current.style.height = `${textInputRef.current.scrollHeight}px`;
      }
    }
  };

  React.useEffect(() => {
    if (textInputRef.current) handleTextInputResize();
    if (!textInputRef.current) setTimeout(handleTextInputResize, 5);
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && e.shiftKey) {
      return;
    }
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <SFakeFooter role="fakeFooter">
      <SChatInputContainer role="inputContainer">
        <SContentContainer role="contentContainer">
          <STextContainer
            $withMargin={!notValid}
            onSubmit={e => e.preventDefault()}
            role="textContainer"
          >
            <STextInputWrapper role="textInputWrapper">
              <STextInput
                role="textInput"
                ref={textInputRef}
                value={text}
                placeholder={translate('messages_writeYourMessage')}
                onChange={e => {
                  handleTextInputResize();
                  setText(e.target.value);
                }}
                onKeyPress={handleKeyPress}
              />
              {limit && (
                <STextCounter role="limitCounter">
                  {textLength}/{limit}
                </STextCounter>
              )}
            </STextInputWrapper>
          </STextContainer>

          {!notValid && (
            <SIcon $notValid={notValid} onClick={handleSubmit} role="sendButton">
              <img src={SendIcon} alt="send" />
            </SIcon>
          )}
        </SContentContainer>
      </SChatInputContainer>
    </SFakeFooter>
  );
};
