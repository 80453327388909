import React, { useEffect, useState } from 'react';
import { Backdrop } from './CheckRequiredAppVersion.styles';
import { ApiVersionsViewModel, getApiVersions } from '../../apis/apiVersionsAPI';
import { ReactComponent as CosafeLogo } from '../../assets/imgs/general/cosafe-logo.svg';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { useOnlineStatus } from './useOnlineStatus';
import isElectron from 'is-electron';
import { ipcRendererSend, ipcRenderOn, removeIpcListener } from '../../utils/electronHooks';
import i18n, { SupportLanguageCodes } from '../../i18n';

interface CheckRequiredAppVersionProps {
  children: React.ReactNode;
}

function hasMinimum(data: unknown): data is ApiVersionsViewModel {
  return typeof data === 'object' && data !== null && 'minimum' in data;
}

export function CheckRequiredAppVersion({ children }: CheckRequiredAppVersionProps) {
  const cosafeApiVersion = process.env.REACT_APP_COSAFE_API_VERSION;
  const [hasCheckedApiVersion, setHasCheckedApiVersion] = useState(false);
  const [isUpdateRequired, setIsUpdateRequired] = useState(false);

  const onAppForeground = () => {
    if (isElectron()) {
      setHasCheckedApiVersion(false);
    }
  };

  const onAppOnline = () => {
    if (isElectron()) {
      setHasCheckedApiVersion(false);
    }
  };

  useOnlineStatus(onAppOnline);

  useEffect(() => {
    if (isElectron()) {
      ipcRenderOn('on-app-foreground', () => onAppForeground());
      return () => {
        removeIpcListener('on-app-foreground', () => {});
      };
    }
  }, []);

  useEffect(() => {
    if (hasCheckedApiVersion) {
      return;
    }

    const checkApiVersion = async () => {
      try {
        if (!isElectron()) {
          setIsUpdateRequired(false);
          return;
        }

        if (!cosafeApiVersion) {
          throw new Error('Missing API version');
        }

        const response = await getApiVersions();
        if (!hasMinimum(response)) {
          throw new Error('Missing minimum API version');
        }
        setIsUpdateRequired(Number(cosafeApiVersion) < response.minimum);
      } catch (ignoreError) {
        // If there is an error when checking the API version, we assume that the version does not require update and render the app.
        setIsUpdateRequired(false);
      } finally {
        setHasCheckedApiVersion(true);
      }
    };

    checkApiVersion();
  }, [hasCheckedApiVersion]);

  return isUpdateRequired ? (
    <Backdrop $blocking={false}>
      <CosafeLogo id="cosafeLogo" />
      <ConfirmDialog
        isOpen={isUpdateRequired}
        title={'update_app_required_header'}
        description="update_app_required_message"
        blockOutsideClick={true}
        dismiss={() => setIsUpdateRequired(false)}
        onSubmit={() =>
          ipcRendererSend(
            'open_link',
            `${process.env.REACT_APP_SUPPORT_APP_UPDATE}?lang=${
              SupportLanguageCodes[i18n.language]
            }`
          )
        }
        confirmText={'update_app_required_button'}
      />
    </Backdrop>
  ) : (
    <>
      {!hasCheckedApiVersion && <Backdrop $blocking={true} />}
      {children}
    </>
  );
}
