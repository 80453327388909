import { ApiCore } from './utils/core';

export const apiVersionsAPI = new ApiCore({
  singleExtra: true,
  url: '',
});

export interface ApiVersionsViewModel {
  minimum: number;
  deprecated: number;
  current: number;
}

export const getApiVersions = async () => {
  return apiVersionsAPI.performExtra<ApiVersionsViewModel>({
    method: 'GET',
    extraResource: 'api-versions',
  });
};
