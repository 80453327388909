import { SImage, SImagesRow, SMonthText } from './ChatMedia.styles';
import { MediaByMonth } from './ChatMedia';

interface ImagesByMonthListProps {
  mediaByMonth: MediaByMonth;
  onImageClick: (imageBase64: string, sent: string) => void;
}

export const ImagesByMonthList = (props: ImagesByMonthListProps) => {
  const { mediaByMonth, onImageClick } = props;
  const { month, images } = mediaByMonth;
  return (
    <div role={`imagesByMonth-${month}`}>
      <SMonthText role="monthText">{month}</SMonthText>
      <SImagesRow role="imagesRow">
        {images.map((img, index) => (
          <SImage
            key={`image-${month}-${index}`}
            role={`image-${month}-${index}`}
            src={img.imageBase64}
            onClick={() => onImageClick(img.imageBase64, img.sent)}
          />
        ))}
      </SImagesRow>
    </div>
  );
};
