import styled from 'styled-components';
import { Button } from '../Button/Button';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SContainer = styled.div`
  margin: 1rem 7% 0 7%;
  border-radius: 0.75rem;
  background-color: ${palette.cloudBurst};
  padding: ${toRem(8)} ${toRem(14)} ${toRem(14)};
`;

export const SHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const SCrossIconContainer = styled.p`
  padding-left: ${toRem(10)};
  padding-top: ${toRem(10)};
  padding-bottom: ${toRem(10)};
  cursor: pointer;

  icon {
    width: ${toRem(14)};
    height: ${toRem(14)};
  }
`;

export const SHeader = styled.p`
  font-family: 'Roboto-Medium';
  color: ${palette.white};
  font-size: 1rem;
`;

export const SMessage = styled.p`
  font-family: 'Roboto-Medium';
  color: ${palette.ashGray};
  font-size: ${toRem(14)};
  line-height: 1rem;
  margin-bottom: ${toRem(14)};
`;

export const SButton = styled(Button)`
  button {
    border-radius: ${toRem(12)};
    height: ${toRem(36)};
    font-size: ${toRem(14)};
  }
`;
