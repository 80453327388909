import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NotificationModal from '../components/NotificationModal/NotificationModal';
import { getTestId } from '../components/SidebarOptions/TestAlarm/testAlarmSlice';
import { reportTestAlarmResult } from '../components/SidebarOptions/TestAlarm/testAlarmSlice/actionCreators';
import { logoutUser } from '../containers/Sidebar/actionCreators';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppOpenedNotificationHandler, DataOnlyHandler } from './notificationHandlers';
import { PushyService } from './PushyService';
import {
  AppNotification,
  EAlertType,
  EIdTypes,
  ENotificationDataProperties,
  ENotificationType,
  Notification,
  NotificationPayload,
  SendMessageType,
} from './types';

export const PushNotifications = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const testId = useAppSelector(getTestId);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notification, setNotification] = useState<Notification>({
    messageId: null,
    title: '',
    description: '',
    alert: EAlertType.none,
  });
  const [notificationData, setNotificationData] = useState<NotificationPayload>();
  const [showModal, setShowModal] = useState(true);
  const [notificationType, setNotificationType] = useState(ENotificationType.message);

  let messageId: number | null = null;

  const navTo = () => {
    if (
      notification.alert === EAlertType.reactivate ||
      notification.alert === EAlertType.started ||
      notification.alert === EAlertType.shared ||
      notification.alert === EAlertType.ended
    ) {
      navigate(notification.messageId ? `checklist/${notification.messageId}` : 'checklists');
    } else if (
      notification.alert === EAlertType.callAlert ||
      notification.alert === EAlertType.callAlertEnded
    ) {
      navigate(notification.messageId ? `oncall/${notification.messageId}` : 'onCallAlert');
    } else if (
      notification.alert === EAlertType.newMuster ||
      notification.alert === EAlertType.muster
    ) {
      navigate(notification.messageId ? `muster/${notification.messageId}` : `imOk`);
    } else {
      navigate(notification.messageId ? `message/${notification.messageId}` : 'chat');
    }
  };

  useEffect(() => {
    if (
      notification.alert !== EAlertType.none &&
      notification.alert !== EAlertType.selfAlarmClosedApp &&
      !showModal
    )
      navTo();
  }, [notification]);

  const formatId = (obj: any) => {
    const idTypeList = Object.keys(EIdTypes);
    const newObj = { ...obj };
    _.forEach(Object.keys(newObj), (key: string) => {
      if (idTypeList.includes(key)) {
        newObj.id = newObj[key];
        delete newObj[key];
      }
      if (newObj?.id) messageId = newObj.id;
    });
    return newObj;
  };

  const parseData = (data: any): any => {
    messageId = null; //reset messageId before each function call
    if (!data) {
      return data;
    }

    const propertyList = Object.keys(ENotificationDataProperties);
    _.forEach(propertyList, (key: string) => {
      if (propertyList.includes(key) && typeof data[key] === 'string') {
        data[key] = formatId(JSON.parse(data[key]));
      }
      if (key === EIdTypes.messageId && data[key]) {
        messageId = data[key];
      }
    });
    return data;
  };

  const defineNotificationType = (data: NotificationPayload) => {
    if (data.onCallAlert || data.onCallAlertEnded) {
      return ENotificationType.onCallAlert;
    } else if (
      data.checklistStarted ||
      data.checklistReactivated ||
      data.checklistEnded ||
      data.checklistShared
    ) {
      return ENotificationType.checklist;
    } else if (data.newMuster || data.muster) {
      return ENotificationType.IamOk;
    } else {
      return ENotificationType.message;
    }
  };

  const onNotification = async ({ notificationPayload, showNotification }: AppNotification) => {
    if (!notificationData) setNotificationData(notificationPayload);
    setShowModal(showNotification);
    const data = parseData(notificationPayload);
    setNotificationType(defineNotificationType(data));
    const isDataOnlyMode = data.dataOnlyNotification !== 'False';
    const activeRoute = window.location.hash;
    const dataOnlyHandler = new DataOnlyHandler(messageId, activeRoute, dispatch);
    const entityChangedEvent = data?.entityChangedEvent;
    const isMessageReactionModified = entityChangedEvent?.entityType === 'MessageReaction';
    const isMessageReplyReactionModified =
      entityChangedEvent?.entityType === 'MessageReplyReaction';
    const isChecklistModified =
      entityChangedEvent?.entityType === 'Checklist' &&
      entityChangedEvent?.entityChangeType === 'Modified';

    const Handler = new AppOpenedNotificationHandler(
      messageId,
      data,
      setNotification,
      setIsModalOpen,
      showNotification
    );

    if (data) {
      dataOnlyHandler.handleMessages();
      const entityId =
        entityChangedEvent?.entityIds?.length > 0 ? entityChangedEvent?.entityIds[0] : null;
      if (data.reply && !isDataOnlyMode && !activeRoute.includes(`message/${messageId}`)) {
        Handler.openNotification({ alert: EAlertType.replay });
      } else if (
        data.groupMessage &&
        !isDataOnlyMode &&
        [
          SendMessageType.RegularMessage,
          SendMessageType.HoldingStatement,
          SendMessageType.Broadcast,
        ].includes(data.groupMessage.type)
      ) {
        Handler.openNotification({ alert: EAlertType.replay });
      } else if (
        ((data.groupMessage && data.groupMessage.type === 2) || data.recalled) &&
        !isDataOnlyMode
      ) {
        Handler.openNotification({
          alert: data.recalled ? EAlertType.recalled : EAlertType.active,
          showIcon: !data.recalled,
          cancelBtnTx: data.recalled ? '' : 'cancel',
        });
      } else if (data.onCallAlert) {
        if (isDataOnlyMode) {
          dataOnlyHandler.handleOnCallAlert();
        } else {
          Handler.openNotification({ alert: EAlertType.callAlert });
        }
      } else if (data.onCallAlertEnded) {
        if (isDataOnlyMode) {
          dataOnlyHandler.handleOnCallAlert();
        } else {
          Handler.openNotification({ alert: EAlertType.callAlertEnded });
        }
      } else if (isChecklistModified) {
        dataOnlyHandler.handleChecklistModified(entityId);
      } else if (data.checklistReactivated) {
        if (isDataOnlyMode) {
          dataOnlyHandler.handleCheckList();
        } else {
          const checklistReactivatedId = data?.checklistReactivated?.id;
          const isChecklistReactivatedView = activeRoute?.includes(
            `checklist/${checklistReactivatedId}`
          );
          if (!isChecklistReactivatedView) {
            Handler.openNotification({ alert: EAlertType.reactivate });
          }
        }
      } else if (data.checklistEnded) {
        if (isDataOnlyMode) {
          dataOnlyHandler.handleCheckList();
        } else {
          const checklistEndedId = data?.checklistEnded?.id;
          const isChecklistEndedView = activeRoute?.includes(`checklist/${checklistEndedId}`);
          if (!isChecklistEndedView) {
            Handler.openNotification({ alert: EAlertType.ended });
          }
        }
      } else if (data.checklistStarted) {
        if (isDataOnlyMode) {
          dataOnlyHandler.handleCheckList();
        } else {
          Handler.openNotification({ alert: EAlertType.started });
        }
      } else if (data.checklistShared) {
        if (isDataOnlyMode) {
          dataOnlyHandler.handleCheckList();
        } else {
          Handler.openNotification({ alert: EAlertType.shared });
        }
      } else if (data.newMuster) {
        if (isDataOnlyMode) {
          dataOnlyHandler.handleIamOk();
        } else {
          Handler.openNotification({ alert: EAlertType.newMuster });
        }
      } else if (data.muster) {
        if (isDataOnlyMode) {
          dataOnlyHandler.handleIamOk();
        } else {
          Handler.openNotification({ alert: EAlertType.muster });
        }
      } else if (data?.personalAlarmWarning && !isDataOnlyMode) {
        Handler.openNotification({
          alert: EAlertType.alarmWarn,
          cancelBtnTx: 'close',
        });
      } else if (data?.data?.LoggedOut) {
        dispatch(logoutUser());
      } else if (data?.data?.SelfAlarmTest && !isDataOnlyMode) {
        if (showNotification) {
          Handler.openNotification({
            alert: EAlertType.selfAlarmOpenApp,
            cancelBtnTx: 'close',
            closeBtnOnly: true,
          });
        } else {
          Handler.openNotification({
            alert: EAlertType.selfAlarmClosedApp,
            cancelBtnTx: 'sidebar_settings_testSuccessFailed',
            confirmBtnTx: 'sidebar_settings_testSuccessWorked',
            title: '',
            description: t('sidebar_settings_testSuccessText'),
          });
        }
      } else if (isMessageReactionModified) {
        dataOnlyHandler.handleMessageReactionsModified(entityId);
      } else if (isMessageReplyReactionModified) {
        dataOnlyHandler.handleMessageReplyReactionsModified(entityId);
      }
    }
  };

  const titleColor = notification.alert === EAlertType.recalled ? 'green' : 'white';

  const closeModal = () => {
    setIsModalOpen(false);
    setNotification({
      messageId: null,
      title: '',
      description: '',
      alert: EAlertType.none,
    });
  };

  const cancelModal = () => {
    closeModal();
    if (notification.alert === EAlertType.selfAlarmClosedApp) {
      dispatch(reportTestAlarmResult(false, testId));
    } else if (notification.alert === EAlertType.selfAlarmOpenApp) {
      onNotification({ notificationPayload: notificationData!, showNotification: false });
    }
    const dataHandler = new DataOnlyHandler(messageId, window.location.hash, dispatch);
    if (notificationType === ENotificationType.onCallAlert) {
      dataHandler.handleOnCallAlert();
    } else if (notificationType === ENotificationType.checklist) {
      dataHandler.handleCheckList();
    } else if (notificationType === ENotificationType.IamOk) {
      dataHandler.handleIamOk();
    }
  };

  const confirmModal = () => {
    if (notification.alert === EAlertType.selfAlarmClosedApp) {
      dispatch(reportTestAlarmResult(true, testId));
    } else {
      navTo();
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <NotificationModal
        isOpen={isModalOpen}
        onSubmit={confirmModal}
        onCancel={cancelModal}
        title={notification.title}
        description={notification.description}
        confirmText={notification.confirmBtnTx || 'open'}
        cancelText={notification.cancelBtnTx || 'btnLater'}
        titleColor={titleColor}
        showIcon={notification?.showIcon}
        showModal={showModal}
        closeBtnOnly={notification.closeBtnOnly}
        stylePreset={notification.alert}
      />
      <PushyService onNotification={onNotification} />
    </>
  );
};
