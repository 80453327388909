import ReactDOM from 'react-dom';
import { translate } from '../../utils/translate';
import { RecipientButton } from '../RecipientButton/RecipientButton';
import { DataType } from './RecipientsSummaryList';
import { SMoreText } from '../../containers/CreateMessage/CreateMessageSummary.styles';

const measureComponentWidth = (component: JSX.Element): Promise<number> => {
  return new Promise(resolve => {
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.visibility = 'hidden';
    container.style.pointerEvents = 'none';
    document.body.appendChild(container);

    // Render the component
    ReactDOM.render(component, container);

    // Measure the width after the DOM has rendered
    setTimeout(() => {
      const width = container.firstElementChild?.getBoundingClientRect().width || 0;

      // Clean up
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);

      resolve(width);
    }, 0); // Defer measurement to the next event loop
  });
};

export const getDisplayedItems = async ({
  itemsList,
  nameProperty,
  width,
  authUserId,
  sortByProperty = false,
  haveRemoveButton = false,
  haveAddButton = false,
  marginHorizontal = 20,
  maxCharsCount,
}: {
  itemsList: DataType[];
  nameProperty: string;
  width: number;
  authUserId?: number;
  sortByProperty?: boolean;
  haveRemoveButton?: boolean;
  haveAddButton?: boolean;
  marginHorizontal?: number;
  maxCharsCount?: number;
}): Promise<DataType[]> => {
  const items = itemsList ? [...itemsList] : [];
  if (sortByProperty) {
    items.sort((a, b) => {
      const aName = a[nameProperty as keyof DataType] as unknown as string;
      const bName = b[nameProperty as keyof DataType] as unknown as string;
      return aName.localeCompare(bName);
    });
  }

  const maxWidth = 416; // 26rem => 16 * 26
  const marginRight = 6;
  const containerWidth = width > maxWidth ? maxWidth : width;
  const addBtnWidth = haveAddButton ? 40 : 0;
  const marginHorizontalTotal = marginHorizontal * 2;
  const firstLineWidth = containerWidth - marginHorizontalTotal - addBtnWidth;
  const secondLineWidth = containerWidth - marginHorizontalTotal;

  let filledWidth = 0;
  let isInSecondLine = false;
  const displayedItems: DataType[] = [];
  for await (const item of items) {
    let text =
      (authUserId && authUserId === item.id
        ? translate('you')
        : (item[nameProperty as keyof DataType] as unknown as string)) ?? '';
    text = maxCharsCount && text.length > maxCharsCount ? `${text.slice(0, maxCharsCount)}.` : text;
    let itemWidth = await measureComponentWidth(
      <RecipientButton name={text} hideRemove={!haveRemoveButton} />
    );
    itemWidth += marginRight;
    if (isInSecondLine && filledWidth + itemWidth < secondLineWidth) {
      filledWidth += itemWidth;
      displayedItems.push(item);
    } else if (!isInSecondLine) {
      if (filledWidth + itemWidth > firstLineWidth) {
        filledWidth = itemWidth;
        isInSecondLine = true;
      } else {
        filledWidth += itemWidth;
      }
      displayedItems.push(item);
    }
  }
  // Remove items from back till moreText can fit
  if (items.length !== displayedItems.length && isInSecondLine) {
    for (const item of [...displayedItems].reverse()) {
      const moreText =
        items.length === displayedItems.length
          ? ''
          : `+${items.length - displayedItems.length} ${translate('more')}`;
      const moreTextWidthTaken = await measureComponentWidth(<SMoreText>{moreText}</SMoreText>);
      const totalFilledWidth = filledWidth + moreTextWidthTaken;
      let text =
        (authUserId && authUserId === item.id
          ? translate('you')
          : (item[nameProperty as keyof DataType] as unknown as string)) ?? '';
      text =
        maxCharsCount && text.length > maxCharsCount ? `${text.slice(0, maxCharsCount)}...` : text;
      let itemWidth = await measureComponentWidth(
        <RecipientButton name={text} hideRemove={!haveRemoveButton} />
      );
      itemWidth += marginRight;
      if (isInSecondLine && totalFilledWidth > secondLineWidth) {
        displayedItems.pop();
        filledWidth -= itemWidth;
      }
      if (totalFilledWidth - itemWidth < secondLineWidth) break;
    }
  }

  return displayedItems as DataType[];
};
